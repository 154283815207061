import { faBan, faBars, faCheck, faClock, faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import CustomInput from 'reactstrap/lib/CustomInput';
import style from './style.js';
import Swal from 'sweetalert2';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  clearScheduleOfCandidates,
  clone,
  isTimeRangeOverlap,
  swapAllIntancesOfCandidate,
} from '../../../../../Services/schedule.js';
import Attendee from './MeetingAttendance/Attendee.js';
import HeaderUnavailablePanel from '../HeaderUnavailablePanel/index.js';
import { useAuth0 } from 'auth0/reactAuth0Spa.js';
import { deleteData } from 'Services/dataApi.js';
import { formatBearerToken } from 'Common.functions.js';
import { useAlert } from 'react-alert';
import Loading from 'Body/Statuses/Loading.js';

const ScheduleDetailModal = ({
  addCandidateToSchedule,
  addUnavailableSlot,
  addUnavailableSlots,
  allowInterviewLimit,
  attendanceData,
  candidateLimit,
  candidates = {},
  clearScheduleBlock,
  clearSpecificCandidateFromSchedule,
  columnTracker,
  dateIsInThePast,
  dContext,
  evaluators = {},
  fetchSchedule,
  fireConfirmationForEditingPastSchedule,
  indexedEvaluatorFlexEvents,
  isAddingCandidate,
  isFetching,
  isSubmitting,
  onToggle,
  pendingCandidates,
  rowTracker,
  scheduleData,
  scheduleID,
  selectedCell,
  selectedScheduleBlock,
  setShowSplitCellModal,
  setShowScheduleDetailModal,
  showScheduleDetailModal,
  splitBlockCandidateTracker,
  scheduleBlockTracker,
  submitGeneratedSchedule,
  updateFlexEvent,
  updateFlexEvents,
  willEditThePast,
  updateAllData,
}) => {
  const [activeScheduleDetailTab, setActiveScheduleDetailTab] = useState('1');
  const [myAttendanceData, setMyAttendanceData] = useState({}); //
  const [fakeUrl, setFakeUrl] = useState('Fetching URL. . .');
  const [ghostCandidates, setGhostCandidates] = useState([]);

  const [meetingURL, setMeetingURL] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [ignoreInterviewLimit, setIgnoreInterviewLimit] = useState(false);
  const [meetingPassword, setMeetingPassword] = useState('');
  const [dialInNumbers, setDialInNumbers] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [candidateHoveredOn, setCandidateHoveredOn] = useState();
  const [showUnavailabilityModal, setShowUnavailabilityModal] = useState(false);
  const [unavailabilityPanelHasChanges, setUnavailabilityPanelHasChanges] = useState();
  const [showUnavailabilityEvaluatorPicker, setShowUnavailabilityEvaluatorPicker] = useState(false);
  const [showGhostCandidatesPicker, setShowGhostCandidatesPicker] = useState(false);

  const [candidatesInFlexEvent, setCandidatesInFlexEvent] = useState([]);
  const [isDeletingUnavailables, setIsDeletingUnavailables] = useState(false);
  const [isSwappingCandidates, setIsSwappingCandidates] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const alert = useAlert();

  useEffect(() => {
    setTimeout(() => {
      setFakeUrl('Meeting has not been generated yet.');
    }, 2000);

    return () => {
      setShowGhostCandidatesPicker(false);
    };
  }, []);

  useEffect(() => {
    setIgnoreInterviewLimit(!allowInterviewLimit);
  }, [allowInterviewLimit]);

  useEffect(() => {
    if (attendanceData) {
      setMyAttendanceData(clone(attendanceData));
    }
    if (
      attendanceData &&
      attendanceData.ScheduleBlocks &&
      selectedScheduleBlock &&
      selectedScheduleBlock.pk_ScheduleBlock
    ) {
      const block = attendanceData.ScheduleBlocks[selectedScheduleBlock.pk_ScheduleBlock];
      if (block) {
        setMeetingURL(block.MeetingUrl);
        if (block.DialInNumbers && block.DialInNumbers.length > 0) {
          setDialInNumbers(block.DialInNumbers);
        }
        if (block.Password) {
          setMeetingPassword(block.Password);
        }
      }
    }
  }, [attendanceData, selectedScheduleBlock]);

  useEffect(() => {
    if (selectedScheduleBlock && selectedScheduleBlock.fk_ScheduleAssignment && selectedCell && selectedCell.row) {
      processGhostCandidates({
        cellCandidates: selectedScheduleBlock.Candidates,
        referenceList: selectedCell.row[selectedScheduleBlock.fk_ScheduleAssignment].FlexEvents,
        referenceObject: candidates,
      });
    }
    if (selectedScheduleBlock && selectedCell && selectedScheduleBlock.column && selectedScheduleBlock.column) {
      getEmailData();
      processCandidates();
    }
  }, [JSON.stringify(selectedScheduleBlock), JSON.stringify(selectedCell)]);

  useEffect(() => {
    setCandidateHoveredOn(null);
    if (showScheduleDetailModal && selectedScheduleBlock.Candidates && selectedScheduleBlock.Candidates.length > 0) {
      setActiveScheduleDetailTab('3');
    }
  }, [showScheduleDetailModal]);

  useEffect(() => {
    if (ghostCandidates && ghostCandidates.length > 0) {
      setActiveScheduleDetailTab('4');
    }
  }, [ghostCandidates]);

  const processGhostCandidates = (data) => {
    const { cellCandidates = [], referenceObject = [] } = data;

    const newGhostCandidates = [];
    cellCandidates.forEach((candidate) => {
      const candidateExists = referenceObject[candidate.pk_Candidate];
      if (!candidateExists) {
        newGhostCandidates.push(candidate);
      }
    });
    console.log('newGhostCandidates: ', newGhostCandidates);

    setGhostCandidates(newGhostCandidates);
  };

  const processCandidates = () => {
    const availableCandidates = JSON.parse(JSON.stringify(sortByLastName(Object.values(candidates))));
    const newCandidatesInFlexEvents = [];
    const dataField = selectedCell.column.dataField;
    const cellFlexEvents = selectedCell.row[dataField] ? selectedCell.row[dataField].FlexEvents || [] : [];
    availableCandidates.forEach((candidate) => {
      const candidateIsInFlexEventForThisCell = cellFlexEvents.find((f) => {
        let hasCandidate = false;
        f.AttendingCandidates.forEach((c) => {
          if (c.pk_Candidate == candidate.pk_Candidate) {
            hasCandidate = true;
          }
        });

        return hasCandidate;
      });

      if (candidateIsInFlexEventForThisCell && !newCandidatesInFlexEvents.includes(candidate.pk_Candidate)) {
        newCandidatesInFlexEvents.push(candidate.pk_Candidate);
      }
    });

    setCandidatesInFlexEvent(newCandidatesInFlexEvents);
  };

  const sortByLastName = (toBeSorted) => {
    return toBeSorted.sort((a, b) => {
      try {
        const textA = a.LastName.toUpperCase();
        const textB = b.LastName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      } catch (e) {
        return 0;
      }
    });
  };

  const getAvailableCandidates = ({ row, column = {}, selectedScheduleBlock }) => {
    const availableCandidates = JSON.parse(JSON.stringify(sortByLastName(Object.values(candidates))));
    const invalidCandidates = [];
    const currentCandidates = [];
    const maxScheduleLimit = candidateLimit;

    let pendingItemKey = '';
    let isAPendingCandidateInRow = false; // is a pendign candidate within the row
    let isAPendingCandidateInColumn = false; // is a pending candidate within the column
    // Delete candidates already in block.
    const cellCandidates =
      selectedScheduleBlock && selectedScheduleBlock.Candidates ? selectedScheduleBlock.Candidates : [];

    cellCandidates.map((candidate) => {
      const availableCandidate =
        availableCandidates[
          availableCandidates.findIndex((c) => {
            return c.pk_Candidate === candidate.pk_Candidate;
          })
        ];

      if (!availableCandidate) {
        return;
      }
      const newCandidate = JSON.parse(JSON.stringify(availableCandidate));

      newCandidate.type = 'current';
      currentCandidates.push(newCandidate);
      const guiltyIndex = availableCandidates.findIndex((c) => {
        return c.pk_Candidate === newCandidate.pk_Candidate;
      });
      availableCandidates.splice(guiltyIndex, 1);
    });

    const cell = row[parseInt(column.dataField)];
    const blockStart = moment(selectedScheduleBlock ? selectedScheduleBlock.slot : row.slot);
    const blockEnd = moment(selectedScheduleBlock ? selectedScheduleBlock.slotEnd : row.slotEnd);
    // =================== calc row/column duplication. ============//
    let tempAvailableCandidates = availableCandidates.slice();
    tempAvailableCandidates.forEach((candidate) => {
      const { pk_Candidate } = candidate;
      const splitData = splitBlockCandidateTracker[pk_Candidate] || [];

      const candidateInstancesInRow = splitData.filter((d) => {
        const { pk_Timeslot } = d;
        return pk_Timeslot == row.pk_Timeslot;
      });

      let hasSplitConflict = false;
      candidateInstancesInRow.forEach((c) => {
        // console.log('c: ');
        const existingBlockStart = moment(c.slot);
        const existingBlockEnd = moment(c.slotEnd);

        const hasTimeRangeOverlap = isTimeRangeOverlap([blockStart, blockEnd], [existingBlockStart, existingBlockEnd]);

        if (hasTimeRangeOverlap) {
          // if (c.pk_Candidate == 19231) {
          //   console.log('overlap with: ', c);
          //   console.log('existingBlockStart: ', existingBlockStart.format('hh:mm A'));
          //   console.log('existingBlockEnd: ', existingBlockEnd.format('hh:mm A'));
          //   console.log('blockStart: ', blockStart.format('hh:mm A'));
          //   console.log('blockEnd: ', blockEnd.format('hh:mm A'));
          // }

          hasSplitConflict = true;
        }
      });
      // TODO: Check instances if all are split cells, then check if in conflict with THIS schedule block.
      // Find Schedule block where candidate occupies entire timeslot for this row.
      const isInBlockForEntireTimeSlot = splitData.find((sd) => {
        const { ColumnTotalNumberOfSplits, ScheduleBlockTotalNumberOfSplits, pk_Timeslot } = sd;
        return !ColumnTotalNumberOfSplits && !ScheduleBlockTotalNumberOfSplits && pk_Timeslot == row.pk_Timeslot;
      });

      const isInRow = hasSplitConflict || isInBlockForEntireTimeSlot;

      const isInColumn =
        column.rawHeader &&
        columnTracker[column.rawHeader.pk_ScheduleAssignment] != null &&
        columnTracker[column.rawHeader.pk_ScheduleAssignment].includes(parseInt(pk_Candidate));

      candidate.isDuplicatePosition = isInRow || isInColumn;
      const candidateIsInFlexEvent = candidatesInFlexEvent.includes(pk_Candidate);
      if (isInRow) {
        candidate.rowOfDuplicate = row.pk_Timeslot;
        candidate.isInRow = isInRow;
      }

      if (isInColumn) {
        candidate.columnOfDuplicate = column.rawHeader.pk_ScheduleAssignment;
        candidate.isInColumn = isInColumn;
      }

      if (hasSplitConflict) {
        candidate.hasSplitConflict = true;
      }

      if (
        isInRow ||
        isInColumn ||
        hasSplitConflict ||
        (maxScheduleLimit <= candidate.CountOfScheduleInterviews && !ignoreInterviewLimit) ||
        candidateIsInFlexEvent
      ) {
        candidate.color = 'gray';
        candidate.type = 'invalid';

        invalidCandidates.push(candidate);
        const guiltyIndex = availableCandidates.findIndex((c) => {
          return c.pk_Candidate === candidate.pk_Candidate;
        });
        availableCandidates.splice(guiltyIndex, 1);
      }
    });
    // ===================================================================//

    // check if pending candidates exists in same row/column
    if (showScheduleDetailModal) {
      tempAvailableCandidates = availableCandidates.slice();

      // check row for pending candidates
      Object.keys(columnTracker).forEach((key) => {
        pendingItemKey = `${row.pk_Timeslot}_${key}`;
        const pendingKeysInThisCell =
          Object.keys(pendingCandidates).filter((key) => {
            return key.indexOf(`${pendingItemKey}_`) >= 0;
          }) || [];

        const pendingItemsInThisCell = pendingKeysInThisCell.map((key) => {
          return pendingCandidates[key];
        });

        pendingItemsInThisCell.forEach((candidatesActual) => {
          const candidateActual = candidatesActual[0];
          let guiltyIndex = availableCandidates.findIndex((item) => {
            return item.pk_Candidate == candidateActual.pk_Candidate;
          });

          if (guiltyIndex >= 0) {
            isAPendingCandidateInRow = true;
            availableCandidates.splice(guiltyIndex, 1);
          }

          guiltyIndex = invalidCandidates.findIndex((item) => {
            return item.pk_Candidate == candidateActual.pk_Candidate;
          });

          if (guiltyIndex < 0) {
            candidateActual.color = 'gray';
            candidateActual.type = 'invalid';
            invalidCandidates.push(candidateActual);
          }
        });
      });

      Object.keys(rowTracker).forEach((key) => {
        pendingItemKey = `${key}_${column.dataField}`;
        const pendingKeysInThisCell =
          Object.keys(pendingCandidates).filter((key) => {
            return key.indexOf(`${pendingItemKey}_`) >= 0;
          }) || [];

        const pendingItemsInThisCell = pendingKeysInThisCell.map((key) => {
          return pendingCandidates[key];
        });

        pendingItemsInThisCell.forEach((candidatesActual) => {
          const candidateActual = candidatesActual[0];
          let guiltyIndex = availableCandidates.findIndex((item) => {
            return item.pk_Candidate == candidateActual.pk_Candidate;
          });

          if (guiltyIndex >= 0) {
            isAPendingCandidateInColumn = true;
            availableCandidates.splice(guiltyIndex, 1);
          }

          guiltyIndex = invalidCandidates.findIndex((item) => {
            return item.pk_Candidate == candidateActual.pk_Candidate;
          });

          if (guiltyIndex < 0) {
            candidateActual.color = 'gray';
            candidateActual.type = 'invalid';
            invalidCandidates.push(candidateActual);
          }
        });
      });
    }

    return [
      ...sortByLastName(currentCandidates),
      ...sortByLastName(availableCandidates),
      ...sortByLastName(invalidCandidates),
    ];
  };

  const getEmailData = () => {
    const { column, row } = selectedCell || {};
    // fk_ScheduleAssignment
    const { rawHeader, dataField } = column || {};
    const currentCandidates = [];
    const availableEvaluators =
      dataField && row[parseInt(dataField)] && row[parseInt(dataField)].availableEvaluators
        ? row[parseInt(dataField)].availableEvaluators.slice()
        : [];
    const availableCandidates = clone(sortByLastName(Object.values(candidates)));
    const cellCandidates =
      selectedScheduleBlock && selectedScheduleBlock.Candidates ? selectedScheduleBlock.Candidates : [];

    cellCandidates.map((candidate) => {
      const availableCandidate =
        availableCandidates[
          availableCandidates.findIndex((c) => {
            return c.pk_Candidate === candidate.pk_Candidate;
          })
        ];

      if (!availableCandidate) {
        return;
      }
      const newCandidate = JSON.parse(JSON.stringify(availableCandidate));
      newCandidate.type = 'current';
      currentCandidates.push(newCandidate);
      const guiltyIndex = availableCandidates.findIndex((c) => {
        return c.pk_Candidate === newCandidate.pk_Candidate;
      });
      availableCandidates.splice(guiltyIndex, 1);
    });

    const attendeeList = [...availableEvaluators, ...currentCandidates];
    const { emailList, nameList } = processAtendeeListNameAndEmails(attendeeList);
    setEmailData({
      emailList,
      nameList,
    });
    return emailList;
  };

  const processAtendeeListNameAndEmails = (list) => {
    let emailList = null;
    let nameList = '';
    list.forEach((a) => {
      if (a.Email || a.Auth0Email) {
        if (emailList) {
          emailList += `,${a.Email || a.Auth0Email}`;
        } else {
          emailList = `${a.Email || a.Auth0Email}`;
        }
      }

      if (nameList) {
        if (a.pk_User) {
          nameList += `, ${a.UserFirst || ''} ${a.UserLast || ''}`;
        } else {
          nameList += `, ${a.FirstName || ''} ${a.LastName || ''}`;
        }
      } else {
        if (a.pk_User) {
          nameList = `${a.UserFirst || ''} ${a.UserLast || ''}`;
        } else {
          nameList = `${a.FirstName || ''} ${a.LastName || ''}`;
        }
      }
    });

    return { emailList, nameList };
  };

  const deleteFlexEvent = (pk_FlexEvents, resolve, reject, callBack) => {
    setIsDeletingUnavailables(true);

    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
          },
          { pk_FlexEvents: pk_FlexEvents },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Deleted successfully!');
            setIsDeletingUnavailables(false);
            if (callBack) {
              callBack();
            } else {
              setShowScheduleDetailModal(false);
            }
            fetchSchedule();
          })
          .catch((err) => {
            setIsDeletingUnavailables(false);
            if (callBack) {
              callBack(true);
            }
            alert.error('Failed to delete!');
          });
      })
      .catch((err) => {
        alert.error('Failed to delete!');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const checkIfScheduleBlockIsUnavailable = (flexEvents, scheduleBlock) => {
    let isUnavailable = false;
    const slot = moment(scheduleBlock.slot);
    const slotEnd = moment(scheduleBlock.slotEnd);

    flexEvents.forEach((flexEvent) => {
      const { FlexTimeStart, FlexTimeEnd, FlexDateStart, FlexDateEnd } = flexEvent;
      const FlexTimeStartActual = moment(`${FlexDateStart} ${FlexTimeStart}`);
      const FlexTimeEndActual = moment(`${FlexDateEnd} ${FlexTimeEnd}`);

      if (
        (FlexTimeStartActual.isSameOrAfter(slot) && FlexTimeStartActual.isBefore(slotEnd)) || // Event start is between rowstart and rowend
        (FlexTimeEndActual.isAfter(slot) && FlexTimeEndActual.isBefore(slotEnd)) || // event end is between rowstart and row end
        (FlexTimeStartActual.isSameOrBefore(slot) && FlexTimeEndActual.isSameOrAfter(slotEnd)) // rowstart and row end is within event start and end
      ) {
        isUnavailable = true;
      }
    });

    return isUnavailable;
  };

  // run through every candidate and do swap
  const processSwap = (data) => {
    const { candidate, scheduleDataRef } = data;
    const { body = [], headers } = scheduleData || {};
    const { rowOfDuplicate, columnOfDuplicate, pk_Candidate } = candidate;
    const blocksToClear = [];

    if (rowOfDuplicate) {
      // Duplicate along row.
      const rowDuplicate = body.find((t) => {
        return t.pk_Timeslot == rowOfDuplicate;
      });

      const splitData = splitBlockCandidateTracker[pk_Candidate] || [];
      const blockStart = moment(selectedScheduleBlock ? selectedScheduleBlock.slot : rowDuplicate.slot);
      const blockEnd = moment(selectedScheduleBlock ? selectedScheduleBlock.slotEnd : rowDuplicate.slotEnd);

      const candidateInstancesInRow = splitData.filter((d) => {
        const { pk_Timeslot } = d;
        return pk_Timeslot == rowOfDuplicate;
      });

      candidateInstancesInRow.forEach((c) => {
        // console.log('c: ');
        const existingBlockStart = moment(c.slot);
        const existingBlockEnd = moment(c.slotEnd);

        const hasTimeRangeOverlap = isTimeRangeOverlap([blockStart, blockEnd], [existingBlockStart, existingBlockEnd]);

        if (hasTimeRangeOverlap) {
          blocksToClear.push(c.pk_ScheduleBlock);
        }
      });
    }

    if (columnOfDuplicate) {
      // Duplicate along column
      const cellsInGuiltyColumn = body.map((t) => {
        return t[columnOfDuplicate];
      });
      let columnDuplicateScheduleBlock;

      cellsInGuiltyColumn.forEach((c) => {
        const blocks = c.ScheduleBlockEntries || [];
        blocks.forEach((b) => {
          if (b.Candidates) {
            const { Candidates } = b;
            Candidates.forEach((c) => {
              if (c.pk_Candidate == candidate.pk_Candidate) {
                columnDuplicateScheduleBlock = b.pk_ScheduleBlock;
              }
            });
          }
        });
      });
      blocksToClear.push(columnDuplicateScheduleBlock);
    }

    clearScheduleBlock(blocksToClear, () => {
      const { column, row } = selectedCell || {};
      const { rawHeader } = column || {};
      const data = {
        pk_ScheduleAssignment: rawHeader.pk_ScheduleAssignment,
        pk_Timeslot: row.pk_Timeslot,
        candidates: [candidate],
        selectedScheduleBlock,
      };

      addCandidateToSchedule(data);
    });
  };

  const fireCandidateInFlexEventAlert = (candidate, flexEvent, callback) => {
    const { CountOfScheduleInterviews, isDuplicatePosition } = candidate;
    Swal.fire({
      title: 'Candidate in a Flex Event!',
      text: `${candidate.FirstName || ''} ${candidate.LastName || ''} is assigned to a Flex Event ${
        flexEvent.FlexEventName ? `"${flexEvent.FlexEventName}"` : '(Unnamed Flex Event)'
      } that overlaps this cell! Remove ${candidate.FirstName || ''} from flex event first.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'grey',
      cancelButtonText: 'Okay',
      confirmButtonColor: 'red',
      confirmButtonText: 'Schedule Anyway',
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          allowInterviewLimit &&
          !ignoreInterviewLimit &&
          candidateLimit <= CountOfScheduleInterviews &&
          !isDuplicatePosition
        ) {
          fireOverLimitAlert(candidate);
          return;
        }

        if (callback) {
          callback();
          return;
        }
        const { column, row } = selectedCell;
        const { rawHeader } = column;

        // const { pk_ScheduleAssignment, pk_Timeslot, candidates } = data;
        const data = {
          pk_ScheduleAssignment: rawHeader.pk_ScheduleAssignment,
          pk_Timeslot: row.pk_Timeslot,
          candidates: [candidate],
          selectedScheduleBlock,
        };

        addCandidateToSchedule(data);
      }
    });
  };

  const fireEvaluatorInFlexEventAlert = (data) => {
    const { candidate, evaluators, flexEvents, callback } = data;
    const { CountOfScheduleInterviews, isDuplicatePosition } = candidate;
    const evaluatorNames = [];
    let names = '';
    evaluators.forEach((evaluator, i) => {
      evaluatorNames.push(`${evaluator.UserLast}, ${evaluator.UserFirst}`);
    });
    evaluatorNames.sort();
    evaluatorNames.forEach((evaluatorName, i) => {
      names += `${evaluators[i].UserFirst[0]}. ${evaluators[i].UserLast}${
        evaluatorNames.length > 1 && i < evaluatorNames.length - 1 ? ', ' : ' '
      }`;
    });

    let flexEventsWithColumnEvaluators = [];
    flexEvents.forEach((flexEvent) => {
      flexEvent.AttendingUsers.forEach((evaluator) => {
        const evaluatorIsInColumn = evaluators.find((e) => {
          return e.pk_User == evaluator.pk_User;
        });

        if (evaluatorIsInColumn) {
          const flexEventExists = flexEventsWithColumnEvaluators.find((fE) => {
            return flexEvent.pk_FlexEvent == fE.pk_FlexEvent;
          });

          if (!flexEventExists) {
            flexEventsWithColumnEvaluators.push(flexEventsWithColumnEvaluators);
          }
        }
      });
    });

    const multipleEvaluators = evaluators.length > 1;
    const multipleFlexEvents = flexEventsWithColumnEvaluators.length > 1;

    Swal.fire({
      title: `${multipleEvaluators ? 'Evaluators' : `Evaluator`} in ${
        multipleFlexEvents ? 'Flex Events' : 'a Flex Event'
      }!`,
      text: `${
        multipleEvaluators ? `Evaluators ${names}` : `Evaluator ${evaluators[0].UserFirst} ${evaluators[0].UserLast}`
      } ${multipleEvaluators ? 'are' : 'is'} assigned to ${
        multipleFlexEvents ? 'Flex Events.' : 'a Flex Event.'
      } Remove ${multipleFlexEvents ? 'Evaluators' : 'Evaluator'} from ${
        multipleFlexEvents ? 'Flex Events' : 'Flex Event'
      } first.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'grey',
      cancelButtonText: 'Okay',
      confirmButtonColor: 'red',
      confirmButtonText: 'Schedule Anyway',
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          allowInterviewLimit &&
          !ignoreInterviewLimit &&
          candidateLimit <= CountOfScheduleInterviews &&
          !isDuplicatePosition
        ) {
          fireOverLimitAlert(candidate);
          return;
        }

        if (callback) {
          callback();
          return;
        }
        const { column, row } = selectedCell;
        const { rawHeader } = column;

        // const { pk_ScheduleAssignment, pk_Timeslot, candidates } = data;
        const data = {
          pk_ScheduleAssignment: rawHeader.pk_ScheduleAssignment,
          pk_Timeslot: row.pk_Timeslot,
          candidates: [candidate],
          selectedScheduleBlock,
        };

        addCandidateToSchedule(data);
      }
    });
  };

  const fireSwapAlert = (candidate) => {
    Swal.fire({
      title: 'Do you want to move this candidate?',
      html: `${candidate.FirstName} ${candidate.LastName} is already scheduled in this ${
        candidate.isInRow && candidate.isInColumn
          ? 'row and column.'
          : candidate.isInRow
          ? 'row'
          : candidate.isInColumn
          ? 'column'
          : ''
      }

      <br/>
      <br/>
      Warning: This WILL remove candidates from already-existing and conflicting ${
        candidate.isInRow && candidate.isInColumn ? 'cells' : 'cell'
      }.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        processSwap({ candidate, scheduleDataRef: scheduleData });
      } else {
      }
    });
  };

  const fireOverLimitAlert = (candidate) => {
    const { CountOfScheduleInterviews } = candidate;
    Swal.fire({
      title: 'Over the Limit!',
      text: `${candidate.FirstName || ''} ${candidate.LastName ||
        ''} already has ${CountOfScheduleInterviews} interviews. The limit is ${candidateLimit} per candidate. Increase the candidate limit or toggle the "Ignore Limits" switch first.`,
      icon: 'warning',
      // showCancelButton: true,
      // cancelButtonColor: 'gray',
      // confirmButtonText: 'Yes',
    });
  };

  const fireUnavailableUnsavedChangesAlert = () => {
    Swal.fire({
      title: 'Unsaved Changes',
      text: 'You have unsaved changes. Are you sure you want to discard these? You cannot undo this.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      confirmButtonText: `Yes`,
    }).then((response) => {
      if (response.isConfirmed) {
        setShowUnavailabilityModal(false);
      }
    });
  };

  const renderAvailableCandidatesTab = () => {
    const availableCandidates = selectedCell ? getAvailableCandidates({ ...selectedCell, selectedScheduleBlock }) : [];
    const { column, row } = selectedCell || {};
    const { rawHeader } = column || {};
    const evaluatorsToBeUnavailable = [];

    if (rawHeader && rawHeader.Evaluators && rawHeader.Evaluators.Evaluators) {
      rawHeader.Evaluators.Evaluators.forEach((evaluator) => {
        evaluatorsToBeUnavailable.push(evaluator.pk_User);
      });
    }

    const slot = row ? row.pk_Timeslot : null;
    const cellActual = column && row ? row[parseInt(column.dataField)] : null;

    const isCellUnavailable =
      cellActual &&
      cellActual.isUnavailable &&
      checkIfScheduleBlockIsUnavailable(cellActual.flexEvents, selectedScheduleBlock || {});

    return (
      <div style={style.availableCandidatesTab}>
        <div style={style.availablCandidatesTabLeft}>
          <div style={style.buttonRow}>
            <Button
              color="warning"
              style={style.genButton}
              disabled={
                !selectedScheduleBlock ||
                selectedScheduleBlock.Candidates == null ||
                selectedScheduleBlock.Candidates.length <= 0
              }
              onClick={async () => {
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }

                clearScheduleBlock();
              }}
            >
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <FontAwesomeIcon icon={faTimesCircle} style={style.buttonIcon} />
                <div style={style.buttonLabel}>Clear Candidate</div>
              </div>
            </Button>
          </div>
          {/* <div style={style.buttonRow}>
            <Button style={style.genButton}>
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <FontAwesomeIcon icon={faMugHot} style={style.buttonIcon} />
                <div style={style.buttonLabel}>Break</div>
              </div>
            </Button>
          </div> */}
          <div style={style.buttonRow}>
            <Button
              id="unavailability_btn"
              disabled={isDeletingUnavailables}
              color="danger"
              style={style.genButton}
              onClick={async () => {
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }
                // setShowUnavailabilityModal(true);

                if (isCellUnavailable) {
                  const pk_FlexEvents = [];
                  let innerHTML = `<div style="display: flex;flex-direction: row; width: 100%; justify-content:center;"><div style="display: flex;flex-direction: column;width: 80%;align-items: center;">`;

                  cellActual.flexEvents.forEach((flexEvent) => {
                    const { FlexEventName = '', FlexTimeStart, FlexTimeEnd, FlexDateStart, FlexDateEnd } = flexEvent;

                    const timeStartToDisplay = moment(`${FlexDateStart} ${FlexTimeStart}`).format('hh:mm A');
                    const timeEndToDisplay = moment(`${FlexDateEnd} ${FlexTimeEnd}`).format('hh:mm A');
                    if (flexEvent.FlexEventName === 'UNAVAILABLE') {
                      pk_FlexEvents.push(flexEvent.pk_FlexEvent);
                      innerHTML += `<div style="display: flex;flex-direction: row;font-size: 13px;width: 80%;margin-top:10px;"></div><div style="display: flex;flex-direction: row;font-size: 13px;width: 80%;font-weight:bold;">${flexEvent.FlexEventName} ${flexEvent.pk_FlexEvent} (${timeStartToDisplay} - ${timeEndToDisplay})
                      </div>`;
                      flexEvent.AttendingUsers.forEach((evaluator) => {
                        innerHTML += `<div style="display: flex;flex-direction: row;font-size: 12px;width: auto;width: 80%;margin-left:40px;">-${evaluator.UserLast ||
                          ''}${evaluator.UserLast && evaluator.UserFirst ? ', ' : ''} ${evaluator.UserFirst ||
                          ''}</div>`;
                      });
                    }
                    // innerHTML += '</div>';
                  });
                  innerHTML += '</div></div>';
                  Swal.fire({
                    title: 'Remove Unavailability?',

                    html: innerHTML,
                    icon: 'warning',
                    showCancelButton: true,
                    showDenyButton: true,
                    denyButtonColor: '#007bff',
                    denyButtonText: 'Manage',
                    cancelButtonColor: '#dc3545',
                    cancelButtonText: 'Remove',
                    confirmButtonText: 'Cancel',
                    confirmButtonColor: 'gray',
                  }).then((result) => {
                    if (result.isDismissed) {
                      deleteFlexEvent(pk_FlexEvents);
                    } else if (result.isDenied) {
                      setShowUnavailabilityModal(true);
                    } else {
                    }
                  });
                } else {
                  const data = {
                    newEvaluators: evaluatorsToBeUnavailable,
                    StartDateTime: moment.tz(selectedScheduleBlock.slot, null).format(),
                    EndDateTime: moment.tz(selectedScheduleBlock.slotEnd, null).format(),
                    eventName: 'UNAVAILABLE',
                    FlexTimeStart: moment.tz(selectedScheduleBlock.slot, null).format('hh:mm A'),
                    FlexTimeEnd: moment.tz(selectedScheduleBlock.slotEnd, null).format('hh:mm A'),
                  };

                  addUnavailableSlot(data);
                }
              }}
            >
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <FontAwesomeIcon icon={faBan} style={style.buttonIcon} />
                <div style={style.buttonLabel}>{isCellUnavailable ? 'Clear Unavailable' : 'Unavailable'}</div>
              </div>
              {renderUnavailabilityModal()}
            </Button>
          </div>
          <div style={style.buttonRow}>
            <Button
              disabled={isCellUnavailable}
              style={style.genButton}
              onClick={async () => {
                if (dateIsInThePast && !willEditThePast) {
                  const continueProcess = await fireConfirmationForEditingPastSchedule();
                  if (!continueProcess) {
                    return;
                  }
                }
                setShowSplitCellModal(true);
              }}
            >
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <FontAwesomeIcon icon={faBars} style={style.buttonIcon} />
                <div style={style.buttonLabel}>Split Cell</div>
              </div>
            </Button>
          </div>
        </div>
        <div style={style.availableCandidatesRight}>
          {allowInterviewLimit ? (
            <div>
              <CustomInput
                type="switch"
                id="ignoreInterviewLimit"
                name="ignoreInterviewLimit"
                label="Ignore interview limit"
                checked={ignoreInterviewLimit}
                onChange={async (e) => {
                  const checkValue = e.target.checked;
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  setIgnoreInterviewLimit(checkValue);
                }}
              />
            </div>
          ) : (
            <br />
          )}

          {candidates && Object.keys(candidates).length > 0 ? (
            <div style={style.availableCandidatesList}>
              {Object.keys(availableCandidates).map((key) => {
                const candidate = availableCandidates[key];
                const { CountOfScheduleInterviews } = candidate;

                let tagText = null;

                if (candidate.Tags) {
                  candidate.Tags.forEach((tag) => {
                    if (tag.Tag) {
                      if (tagText == null) {
                        tagText = `(${tag.Tag}`;
                      } else {
                        tagText += `, ${tag.Tag}`;
                      }
                    }
                  });
                  if (tagText != null) {
                    tagText += ')';
                  }
                } else {
                  tagText = '(No Tags)';
                }

                return (
                  <div
                    style={style.availableCandidate({ c: candidate, selectedCandidates, candidateHoveredOn })}
                    onMouseEnter={() => {
                      setCandidateHoveredOn(candidate);
                    }}
                    onMouseLeave={() => {
                      setCandidateHoveredOn(null);
                    }}
                    onClick={async () => {
                      if (dateIsInThePast && !willEditThePast) {
                        const continueProcess = await fireConfirmationForEditingPastSchedule();
                        if (!continueProcess) {
                          return;
                        }
                      }
                      const cellFlexEvents = selectedCell.row[selectedCell.column.dataField].FlexEvents || [];
                      const candidateIsInFlexEventForThisCell = cellFlexEvents.find((f) => {
                        let hasCandidate = false;
                        f.AttendingCandidates.forEach((c) => {
                          if (c.pk_Candidate == candidate.pk_Candidate) {
                            hasCandidate = true;
                          }
                        });

                        return hasCandidate;
                      });

                      let hasEvaluatorInFlexEvent = false;

                      if (
                        selectedScheduleBlock &&
                        selectedScheduleBlock.flexEventEvaluators &&
                        selectedScheduleBlock.flexEventEvaluators.length > 0
                      ) {
                        hasEvaluatorInFlexEvent = true;
                      }

                      const { column, row } = selectedCell;
                      const { rawHeader } = column;

                      // const { pk_ScheduleAssignment, pk_Timeslot, candidates } = data;
                      const data = {
                        pk_ScheduleAssignment: rawHeader.pk_ScheduleAssignment,
                        pk_Timeslot: row.pk_Timeslot,
                        candidates: [candidate],
                        selectedScheduleBlock,
                      };
                      const canFireOverLimit =
                        allowInterviewLimit && !ignoreInterviewLimit && candidateLimit <= CountOfScheduleInterviews;

                      if (candidate.type === 'current') {
                        Swal.fire({
                          title: 'Delete?',
                          text: `Are you sure you want to Unschedule ${candidate.FirstName ||
                            ''} ${candidate.LastName || ''}?`,
                          icon: 'warning',
                          showCancelButton: true,
                          cancelButtonColor: 'gray',
                          confirmButtonText: 'Yes',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            clearScheduleBlock();
                          } else {
                          }
                        });
                      } else if (candidate.type == null || candidate.type != 'current') {
                        if (hasEvaluatorInFlexEvent) {
                          fireEvaluatorInFlexEventAlert({
                            candidate,
                            evaluators: selectedScheduleBlock.flexEventEvaluators,
                            flexEvents: selectedScheduleBlock.FlexEvents,
                            callback: () => {
                              if (candidateIsInFlexEventForThisCell) {
                                fireCandidateInFlexEventAlert(candidate, candidateIsInFlexEventForThisCell, () => {
                                  if (candidate.isDuplicatePosition) {
                                    fireSwapAlert(candidate);
                                  } else {
                                    if (canFireOverLimit) {
                                      fireOverLimitAlert(candidate);
                                      return;
                                    }
                                    addCandidateToSchedule(data);
                                  }
                                });
                              } else if (candidate.isDuplicatePosition) {
                                fireSwapAlert(candidate);
                              } else {
                                if (canFireOverLimit) {
                                  fireOverLimitAlert(candidate);
                                  return;
                                }
                                addCandidateToSchedule(data);
                              }
                            },
                          });
                        } else if (candidateIsInFlexEventForThisCell) {
                          fireCandidateInFlexEventAlert(candidate, candidateIsInFlexEventForThisCell, () => {
                            if (candidate.isDuplicatePosition) {
                              fireSwapAlert(candidate);
                            } else {
                              if (canFireOverLimit) {
                                fireOverLimitAlert(candidate);
                                return;
                              }
                              addCandidateToSchedule(data);
                            }
                          });
                        } else if (candidate.isDuplicatePosition) {
                          fireSwapAlert(candidate);
                        } else {
                          if (canFireOverLimit) {
                            fireOverLimitAlert(candidate);
                            return;
                          }
                          addCandidateToSchedule(data);
                        }
                      }
                    }}
                  >
                    <div style={style.simpleRow} disabled={isAddingCandidate}>
                      {
                        <div style={{ ...style.simpleColumn, width: 40 }}>
                          {candidate.type === 'current' ? <FontAwesomeIcon icon={faCheck} /> : null}
                        </div>
                      }
                      <div style={{ ...style.simpleColumn, width: '100%' }}>
                        {candidate.LastName}
                        {candidate.LastName ? ', ' : ''}
                        {candidate.FirstName}
                        {` (${CountOfScheduleInterviews != null ? CountOfScheduleInterviews : 0} interviews) `}{' '}
                        {tagText}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={style.noCandidateBlock}>No Available Candidates for block</div>
          )}
        </div>
      </div>
    );
  };

  const renderEvaluatorsTab = () => {
    const { column, row } = selectedCell || {};
    const { rawHeader, dataField } = column || {};
    const availableEvaluators =
      dataField && row[parseInt(dataField)] && row[parseInt(dataField)].availableEvaluators
        ? row[parseInt(dataField)].availableEvaluators.slice()
        : [];
    const unavailableEvaluators =
      dataField && row[parseInt(dataField)] && row[parseInt(dataField)].unavailableEvaluators
        ? row[parseInt(dataField)].unavailableEvaluators.slice()
        : [];

    let columnEvaluators = [...availableEvaluators, ...unavailableEvaluators];
    if (columnEvaluators.length <= 0) {
      if (rawHeader && rawHeader.Evaluators && rawHeader.Evaluators.Evaluators) {
        rawHeader.Evaluators.Evaluators.forEach((evaluator) => {
          columnEvaluators.push(evaluator.pk_User);
        });
      }
    }

    const slot = row ? row.pk_Timeslot : null;

    return (
      <div style={style.evaluatorsMainContainer}>
        <div style={style.evaluatorListContainer}>
          {columnEvaluators.map((evaluator, index) => {
            const { pk_User } = evaluator;
            let isUnavailable = false;
            let unavailableIndex = unavailableEvaluators.findIndex((item) => {
              return item.pk_User === pk_User;
            });
            if (unavailableIndex >= 0) {
              isUnavailable = true;
            }

            const backgroundColor = index % 2 == 0 ? '#d9f5ff' : null;
            return (
              <div style={{ ...style.evaluatorItem, backgroundColor }}>
                <div style={{ ...style.simpleColumn, width: '13%' }}>
                  {evaluator.UserPhotoUrl ? (
                    <img src={evaluator.UserPhotoUrl} height={50} width={50} style={style.evaluatorImage} />
                  ) : (
                    <FontAwesomeIcon icon={faUserCircle} style={style.attendeeProfilePlaceHolder} />
                  )}
                </div>
                <div style={style.evaluatorName}>{`${evaluator.UserLast}, ${evaluator.UserFirst}`}</div>
                <div
                  style={{
                    ...style.simpleColumn,
                    maxWidth: '30%',
                    minWidth: 200,
                    justifyContent: 'center',
                    fontSize: 20,
                    color: isUnavailable ? 'red' : 'green',
                  }}
                >
                  {isUnavailable ? (
                    <div>
                      <FontAwesomeIcon icon={faBan} style={{ ...style.buttonIcon, cursor: 'default' }} />
                      Unavailable
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faClock} style={{ ...style.buttonIcon, cursor: 'default' }} />
                      Available
                    </div>
                    // <Button
                    //   color="success"
                    //   disabled={isUnavailable}
                    //   style={style.genButton}
                    //   onClick={async () => {
                    //     if (dateIsInThePast && !willEditThePast) {
                    //       const continueProcess = await fireConfirmationForEditingPastSchedule();
                    //       if (!continueProcess) {
                    //         return;
                    //       }
                    //     }
                    //   }}
                    // >
                    //   <FontAwesomeIcon icon={faClock} style={style.buttonIcon} />
                    //   <div style={style.buttonLabel}>Set Available</div>
                    // </Button>
                    // <Button
                    //   color="danger"
                    //   disabled={isUnavailable}
                    //   style={style.genButton}
                    //   onClick={async () => {
                    //     if (dateIsInThePast && !willEditThePast) {
                    //       const continueProcess = await fireConfirmationForEditingPastSchedule();
                    //       if (!continueProcess) {
                    //         return;
                    //       }
                    //     }
                    //     const data = {
                    //       newEvaluators: [pk_User],
                    //       fk_TimeslotStart: slot,
                    //       fk_TimeslotEnd: slot,
                    //       StartDateTime: moment(row.slot).format(),
                    //       EndDateTime: moment(row.slotEnd).format(),
                    //       FlexTimeStart: moment(row.slot).format('hh:mm A'),
                    //       FlexTimeEnd: moment(row.slotEnd).format('hh:mm A'),
                    //       eventName: 'UNAVAILABLE',
                    //     };

                    //     addUnavailableSlot(data);
                    //   }}
                    // >
                    //   <FontAwesomeIcon icon={faBan} style={style.buttonIcon} />
                    //   <div style={style.buttonLabel}>Set Unavailable</div>
                    // </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderMeetingAttendanceTab = () => {
    if (!selectedCell) {
      return;
    }

    const cellCandidates = [];
    const cellEvaluators = [];
    let attendingCandidates = 0;
    let attendingEvaluators = 0;
    let attendanceObject = {};

    if (myAttendanceData) {
      const { ScheduleBlocks, TimeSlots } = myAttendanceData;
      if (ScheduleBlocks && selectedScheduleBlock && ScheduleBlocks[selectedScheduleBlock.pk_ScheduleBlock]) {
        attendanceObject = ScheduleBlocks[selectedScheduleBlock.pk_ScheduleBlock];
      } else if (TimeSlots && TimeSlots[selectedCell.row.pk_Timeslot]) {
        attendanceObject = TimeSlots[selectedCell.row.pk_Timeslot];
      }
    }

    if (selectedScheduleBlock) {
      const sb = selectedScheduleBlock;
      // selectedCell.row[selectedCell.column.dataField].ScheduleBlockEntries.forEach((sb) => {
      if (sb.Candidates) {
        sb.Candidates.forEach((sbc) => {
          const isAttending = attendanceObject.Candidates
            ? attendanceObject.Candidates.find((c) => {
                return c.fk_Candidate == sbc.pk_Candidate;
              })
            : null; // Math.random() * 11 > 5;
          if (isAttending) {
            attendingCandidates++;
          }
          cellCandidates.push({ ...clone(candidates[sbc.pk_Candidate]), isAttending });
        });
      }
      // });
    }

    if (selectedCell.column && selectedCell.column.rawHeader) {
      const rawHeader = selectedCell.column.rawHeader;
      if (rawHeader.Evaluators && rawHeader.Evaluators.Evaluators) {
        rawHeader.Evaluators.Evaluators.forEach((evaluator) => {
          const isAttending = attendanceObject.Evaluators
            ? attendanceObject.Evaluators.find((e) => {
                return e.fk_User == evaluator.pk_User;
              })
            : null; // Math.random() * 11 > 5;
          if (isAttending) {
            attendingEvaluators++;
          }
          cellEvaluators.push({ ...clone(evaluators[evaluator.pk_User]), isAttending });
        });
      }
    }

    const attendees = [
      { label: `Candidates (${attendingCandidates} / ${cellCandidates.length})` },
      ...cellCandidates,
      { label: `Evaluators (${attendingEvaluators} / ${cellEvaluators.length})` },
      ...cellEvaluators,
    ];

    if (attendees.length == 2) {
      attendees.splice(0, attendees.length);
    }

    const populateDialInNumbers = () => {
      let dialInNumbersAsString = '';

      dialInNumbers.forEach((item, i) => {
        const { country, number } = item;
        dialInNumbersAsString += `Country ${country} Number ${number}`;

        if (dialInNumbers.length !== i) {
          dialInNumbersAsString += '%0d%0a';
        }
      });
      return dialInNumbersAsString;
    };

    const timeStart = selectedCell ? moment(selectedCell.row.slot).format('hh:mm A') : '';
    const timeEnd = selectedCell ? moment(selectedCell.row.slotEnd).format('hh:mm A') : '';
    const timeZone = scheduleData ? moment.tz(scheduleData.metaData.TimeZone).zoneAbbr() : '';

    return (
      <div style={style.meetingAttendanceTab}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          <div style={{ ...style.simpleRow, minHeight: 290 }}>
            <div style={{ ...style.attendeeList, width: '100%', height: '36vh' }}>
              {attendees.length > 0 ? (
                attendees.map((a, i) => {
                  if (a.label) {
                    return (
                      <div
                        style={{
                          borderBottom: '1px solid #cfcfcf',
                          fontSize: 12,
                          fontWeight: 'bold',
                          borderTop: i == 0 ? '1px solid #cfcfcf' : null,
                        }}
                      >
                        {a.label}
                      </div>
                    );
                  }
                  return (
                    <Attendee
                      attendee={a}
                      index={i}
                      attendees={clone(attendees)}
                      timeStart={timeStart}
                      timeEnd={timeEnd}
                      timeZone={timeZone}
                      meetingURL={meetingURL}
                      meetingPassword={meetingPassword}
                      populateDialInNumbers={populateDialInNumbers}
                      fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
                      dateIsInThePast={dateIsInThePast}
                      willEditThePast={willEditThePast}
                    />
                  );
                })
              ) : (
                <div style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}> No Attendees</div>
              )}
            </div>
          </div>
          <div style={{ ...style.simpleRow, marginTop: 20, width: '80%', alignSelf: 'center' }}>
            <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <InputGroup>
                <Input
                  type="text"
                  disabled
                  style={{ ...style.inputFields, textAlign: 'center' }}
                  name="meetingUrl"
                  id="meetingUrl"
                  value={meetingURL || 'Meeting has not been generated yet.'}
                  placeholder=""
                />

                {/* <InputGroupAddon addonType="append">
            <Button
              color={isValidTime() ? 'success' : 'secondary'}
              disabled={!isValidTime()}
              onClick={() => {
                setFakeUrl('Fetching URL. . .');
                setTimeout(() => {
                  setFakeUrl('www.fakeURL.com/room/mblubaskug');
                }, 3000);
              }}
            >
              Get URL
            </Button>
          </InputGroupAddon> */}
                {meetingURL ? (
                  <InputGroupAddon addonType="append" style={{ height: 38 }}>
                    <Button
                      color={'success'}
                      onClick={() => {
                        if (meetingURL) {
                          window.open(`${meetingURL}`, '_blank');
                        }
                      }}
                    >
                      Join
                    </Button>
                  </InputGroupAddon>
                ) : null}
                {meetingURL ? (
                  <InputGroupAddon addonType="append" style={{ height: 38 }}>
                    <Button
                      color={'primary'}
                      onClick={() => {
                        const input = document.getElementById('meetingUrl');
                        navigator.clipboard.writeText(meetingURL);
                        Swal.fire({
                          title: 'Meeting URL Copied!',
                          html: '',
                          timer: 2000,
                          icon: 'info',
                          showConfirmButton: false,
                          timerProgressBar: true,
                        });
                      }}
                    >
                      Copy
                    </Button>
                  </InputGroupAddon>
                ) : null}
                {meetingURL ? (
                  <InputGroupAddon addonType="append" style={{ height: 38 }}>
                    <Button
                      color={'primary'}
                      onClick={() => {
                        document.getElementById('sendEmailProper').click();
                      }}
                    >
                      Email
                    </Button>
                  </InputGroupAddon>
                ) : null}
              </InputGroup>
              <div style={{ fontWeight: 'bold' }}>This link is unique to this meeting.</div>
            </div>

            <a
              style={{ display: 'none' }}
              id="sendEmailProper"
              href={`mailto:${emailData.emailList || ''}?subject=Meeting with ${emailData.nameList ||
                'No one'}!&body=Start Time: ${timeStart} ${timeZone}%0d%0aEnd Time: ${timeEnd} ${timeZone}%0d%0aJoin Meeting: ${meetingURL}%0d%0aPassword:${meetingPassword}%0d%0aDetails:%0d%0a${populateDialInNumbers()}`}
            ></a>
          </div>
        </div>
      </div>
    );
  };

  const renderUnavailabilityModal = () => {
    if (
      selectedCell === null ||
      selectedScheduleBlock == null ||
      selectedCell.column == null ||
      selectedScheduleBlock.column == null
    ) {
      return;
    }

    const { slot, slotEnd } = selectedScheduleBlock;
    const { row, column = {} } = selectedCell;
    const { rawHeader = {} } = column;
    const timeStart = moment.tz(slot, null).format('hh:mm A');
    const timeEnd = moment.tz(slotEnd, null).format('hh:mm A');
    const day = moment.tz(slot, null).format('MMM DD, YYYY');

    if (column.dataField === 'flexColumn') {
      return;
    }
    const columnFlexEvents = rawHeader && rawHeader.flexEvents ? rawHeader.flexEvents : [];
    const cellActual = row[column.dataField] || {};
    const cellFlexEventPks = cellActual.flexEvents
      ? cellActual.flexEvents.map((f) => {
          return f.pk_FlexEvent;
        })
      : [];
    const cellFlexEvents = columnFlexEvents
      .filter((f) => {
        return cellFlexEventPks.includes(f.pk_FlexEvent);
      })
      .sort((a, b) => {
        return moment(`${day} ${a.FlexTimeStart}`).isBefore(moment(`${day} ${b.FlexTimeStart}`));
      });

    const fakeHeader = clone(column);
    fakeHeader.rawHeader.flexEvents = clone(cellFlexEvents);

    const evaluatorNames = [];
    const evaluatorsArray =
      fakeHeader.rawHeader && fakeHeader.rawHeader.Evaluators ? fakeHeader.rawHeader.Evaluators.Evaluators : [];

    let names = '';
    evaluatorsArray.forEach((evaluator, i) => {
      evaluatorNames.push(`${evaluator.UserLast}, ${evaluator.UserFirst}`);
    });
    evaluatorNames.sort();
    evaluatorNames.forEach((evaluatorName, i) => {
      names += `${evaluatorsArray[i].UserFirst[0]}. ${evaluatorsArray[i].UserLast}${
        evaluatorNames.length > 1 && i < evaluatorNames.length - 1 ? ', ' : ' '
      }`;
    });
    return (
      <Modal isOpen={showUnavailabilityModal} style={style.scheduleDetailModal}>
        <ModalHeader
          toggle={() => {
            if (unavailabilityPanelHasChanges) {
              fireUnavailableUnsavedChangesAlert();
            } else {
              setShowUnavailabilityModal(false);
            }
          }}
        >
          Unavailability for {`${names}`}
        </ModalHeader>
        <ModalBody>
          <div>
            <HeaderUnavailablePanel
              addUnavailableSlot={addUnavailableSlot}
              addUnavailableSlots={addUnavailableSlots}
              dateIsInThePast={dateIsInThePast}
              deleteFlexEvent={deleteFlexEvent}
              fetchSchedule={fetchSchedule}
              fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
              fireUnsavedChangesAlert={fireUnavailableUnsavedChangesAlert}
              header={fakeHeader}
              isSubmitting={isSubmitting}
              isFetching={isFetching}
              scheduleData={scheduleData}
              scheduleID={scheduleID}
              selectedCell={selectedCell}
              setShowUnavailabilityEvaluatorPicker={setShowUnavailabilityEvaluatorPicker}
              setShowUnavailabilityModal={setShowUnavailabilityModal}
              setUnavailabilityPanelHasChanges={setUnavailabilityPanelHasChanges}
              showUnavailabilityEvaluatorPicker={showUnavailabilityEvaluatorPicker}
              updateAllData={updateAllData}
              updateFlexEvent={updateFlexEvent}
              updateFlexEvents={updateFlexEvents}
              willEditThePast={willEditThePast}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderSwappingLoadingModal = () => {
    return (
      <Modal isOpen={isSwappingCandidates} centered style={style.scheduleDetailModal}>
        <ModalBody>
          <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
            <div style={style.simpleRow}>
              <div style={style.simpleColumn}>
                <div style={style.simpleRow}>
                  <Loading
                    options={{
                      labelText: 'Swapping Candidates...',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderGhostCandidates = () => {
    return (
      <div style={{ ...style.simpleRow, height: '100%' }}>
        <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'space-between' }}>
          <div style={{ ...style.simpleRow, height: '25vh', marginTop: 20, marginBottom: 15 }}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              {showGhostCandidatesPicker
                ? renderGhostCandidatesCandidatePicker()
                : ghostCandidates.map((ghost) => {
                    return (
                      <div
                        style={{
                          ...style.spacedBetweenRow,
                          width: '100%',
                          border: '1px gray solid',
                          height: 50,
                          marginBottom: 5,
                        }}
                      >
                        <div style={style.buttonLabel}>
                          {ghost.FirstName} {ghost.LastName}
                        </div>
                      </div>
                    );
                    // return (
                    //   <Button outline style={{ ...style.genButton, cursor: 'normal' }}>
                    //     <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                    //       <div style={style.buttonLabel}>
                    //         {ghost.FirstName} {ghost.LastName}
                    //       </div>
                    //     </div>
                    //   </Button>
                    // );
                  })}
            </div>
          </div>
          <div style={{ ...style.simpleRow, marginTop: 15 }}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              {renderSwappingLoadingModal()}
              {showGhostCandidatesPicker ? (
                <Button
                  color="primary"
                  style={{ ...style.genButton, marginBottom: 5, marginLeft: 0 }}
                  onClick={() => {
                    setShowGhostCandidatesPicker(false);
                  }}
                >
                  <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                    <div style={style.buttonLabel}>Back</div>
                  </div>
                </Button>
              ) : (
                <>
                  <Button
                    color="primary"
                    style={{ ...style.genButton, marginBottom: 5, marginLeft: 0 }}
                    onClick={() => {
                      setShowGhostCandidatesPicker(true);
                    }}
                  >
                    <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                      <div style={style.buttonLabel}>Replace with Other Candidate</div>
                    </div>
                  </Button>
                  <Button
                    color="danger"
                    style={{ ...style.genButton, marginBottom: 5, marginLeft: 0 }}
                    onClick={async () => {
                      if (dateIsInThePast && !willEditThePast) {
                        const continueProcess = await fireConfirmationForEditingPastSchedule();
                        if (!continueProcess) {
                          return;
                        }

                        Swal.fire({
                          title: 'Clear Candidate?',
                          text: 'Are you sure you want to clear this candidate from the schedule?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          cancelButtonText: 'No',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            clearSpecificCandidateFromSchedule(
                              ghostCandidates.map((c) => {
                                return c.pk_Candidate;
                              }),
                            );
                            setShowScheduleDetailModal(false);
                          }
                        });
                      }
                    }}
                  >
                    <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                      <div style={style.buttonLabel}>Clear From Entire Schedule</div>
                    </div>
                  </Button>
                </>
              )}

              {/* <Button color="primary" style={{ ...style.genButton, marginBottom: 5 }}>
                <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                  <div style={style.buttonLabel}>Replace with Other Candidate Across Schedule</div>
                </div>
              </Button> */}
            </div>
          </div>
          {/* <div style={style.simpleRow}>
            <Button color="primary" style={style.genButton}>
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <div style={style.buttonLabel}>Clear From Entire Schedule</div>
              </div>
            </Button>
            <Button color="primary" style={style.genButton}>
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <div style={style.buttonLabel}>Replace with Other Candidate</div>
              </div>
            </Button>
            <Button color="primary" style={style.genButton}>
              <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                <div style={style.buttonLabel}>Replace with Other Candidate Across Schedule</div>
              </div>
            </Button>
          </div> */}
        </div>
      </div>
    );
  };

  const renderGhostCandidatesCandidatePicker = () => {
    const availableCandidates = selectedCell ? getAvailableCandidates({ ...selectedCell, selectedScheduleBlock }) : [];

    return (
      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          <div style={{ ...style.simpleRow, height: '25vh', marginTop: 20, marginBottom: 10, overflowY: 'auto' }}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              {candidates &&
                availableCandidates.map((c) => {
                  const candidate = candidates[c.pk_Candidate];

                  return (
                    <div
                      outline
                      style={style.availableCandidate({ c: candidate, selectedCandidates, candidateHoveredOn })}
                      onMouseEnter={() => {
                        setCandidateHoveredOn(candidate);
                      }}
                      onMouseLeave={() => {
                        setCandidateHoveredOn(null);
                      }}
                      onClick={async () => {
                        if (dateIsInThePast && !willEditThePast) {
                          const continueProcess = await fireConfirmationForEditingPastSchedule();
                          if (!continueProcess) {
                            return;
                          }
                        }
                        Swal.fire({
                          title: `Are you sure you want to swap ${ghostCandidates[0].FirstName} ${ghostCandidates[0].LastName} with ${candidate.FirstName} ${candidate.LastName}?`,
                          text: `This will replace all instances of ${ghostCandidates[0].FirstName} ${ghostCandidates[0].LastName} with ${candidate.FirstName} ${candidate.LastName} in the schedule. This will delete any duplicate positions of ${candidate.FirstName} ${candidate.LastName} in the schedule.`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          cancelButtonText: 'No',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setIsSwappingCandidates(true);
                            const newScheduleBody = swapAllIntancesOfCandidate({
                              scheduleData,
                              oldCandidate: ghostCandidates[0],
                              newCandidate: { ...candidate, ...c },
                              selectedCell,
                              ignoreInterviewLimit,
                              trackers: {
                                columnTracker,
                                rowTracker,
                                splitBlockCandidateTracker,
                                scheduleBlockTracker,
                              },
                              pendingCandidates,
                              candidateLimit,
                              candidates,
                            });

                            submitGeneratedSchedule(newScheduleBody, (err) => {
                              setIsSwappingCandidates(false);
                              if (err) {
                                Swal.fire({
                                  title: 'Error',
                                  text: 'Something went wrong. Please try again.',
                                  icon: 'error',
                                });
                              } else {
                                setShowScheduleDetailModal(false);
                              }
                            });
                          }
                        });
                      }}
                    >
                      <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                        <div style={style.buttonLabel}>
                          {candidate.FirstName} {candidate.LastName}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  let headerTitle = '';

  if (selectedCell) {
    const { metaData } = scheduleData;
    const { StandardDurationInMinutes, StandardPassingDurationInMinutes } = metaData;

    const { row, column } = selectedCell;
    const { CustomDurationInMinutes, CustomPassingDurationInMinutes } = row;
    const blockStart = moment(selectedScheduleBlock ? selectedScheduleBlock.slot : row.slot);
    const blockEnd = moment(selectedScheduleBlock ? selectedScheduleBlock.slotEnd : row.slotEnd);
    let duration = blockEnd.diff(blockStart, 'minutes');
    let durationUnit = duration > 1 ? 'mins' : 'min';

    if (duration > 59) {
      duration = blockEnd.diff(blockStart, 'hours', true).toFixed(2);
      durationUnit = duration > 1 ? 'hrs' : 'hr';
    }

    headerTitle = `${column ? column.text : ''} at ${blockStart.format('MMM DD, YYYY hh:mm A')} - ${blockEnd.format(
      'hh:mm A',
    )} (${duration}${durationUnit})`;
  }

  return (
    <Modal isOpen={showScheduleDetailModal} style={style.scheduleDetailModal} toggle={onToggle} fade={false} centered>
      <ModalHeader closeButton toggle={onToggle}>
        <div style={style.buttonLabel}>{headerTitle}</div>
      </ModalHeader>
      <ModalBody style={style.scheduleDetailModalBody}>
        <Nav tabs>
          {ghostCandidates && ghostCandidates.length > 0 ? (
            <NavItem style={style.tabStyle({ ghostCandidates })}>
              <NavLink
                className={classnames({ active: activeScheduleDetailTab === '4' })}
                style={style.tabTextStyle}
                onClick={() => {
                  setActiveScheduleDetailTab('4');
                }}
              >
                Missing Candidate
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem style={style.tabStyle({ ghostCandidates })}>
            <NavLink
              className={classnames({ active: activeScheduleDetailTab === '1' })}
              style={style.tabTextStyle}
              onClick={() => {
                setActiveScheduleDetailTab('1');
              }}
            >
              Available Candidates
            </NavLink>
          </NavItem>
          <NavItem style={style.tabStyle({ ghostCandidates })}>
            <NavLink
              className={classnames({ active: activeScheduleDetailTab === '2' })}
              style={style.tabTextStyle}
              onClick={() => {
                setActiveScheduleDetailTab('2');
              }}
            >
              Evaluators
            </NavLink>
          </NavItem>
          <NavItem style={style.tabStyle({ ghostCandidates })}>
            <NavLink
              className={classnames({ active: activeScheduleDetailTab === '3' })}
              style={style.tabTextStyle}
              onClick={() => {
                setActiveScheduleDetailTab('3');
              }}
            >
              Meeting Attendance
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent style={{ height: '100%' }} activeTab={activeScheduleDetailTab}>
          <TabPane style={{ height: '90%' }} tabId="4">
            {renderGhostCandidates()}
          </TabPane>
          <TabPane style={{ height: '90%' }} tabId="1">
            {renderAvailableCandidatesTab()}
          </TabPane>
          <TabPane style={{ height: '90%' }} tabId="2">
            {renderEvaluatorsTab()}
          </TabPane>
          <TabPane style={{ height: '90%' }} tabId="3">
            {renderMeetingAttendanceTab()}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ScheduleDetailModal;
