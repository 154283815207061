import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import style from './ApplicationsPage.style';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { fetchData, fetchDataAgnostic } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import moment from 'moment-timezone';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApplicationForm from '../ApplyPage/ApplicationForm/ApplicationForm';
import { useAlert } from 'react-alert';

const ApplicationsPage = (props) => {
  const { applicantDetails } = props;

  // State variables
  const [applications, setApplications] = useState([]);

  const [showApplicationDetailModal, setShowApplicationDetailModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingApplcationDetails, setIsFetchingApplicationDetails] = useState(false);

  const applicationDetails = useRef(null);
  const kindeAuth = useKindeAuth();
  const { isAuthenticated, login, getToken } = kindeAuth || {};

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const { pathname } = history.location;
  const alert = useAlert();
  useEffect(() => {
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    //   setApplications([
    //     {
    //       pk_Application: 1,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Omni Enforcer',
    //       submitted: 'June 14, 2024',
    //       status: 'Not Submitted',
    //     },
    //     {
    //       pk_Application: 2,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'Minerva Corps',
    //       submitted: 'June 14, 2024',
    //       status: 'In Review',
    //     },
    //     {
    //       pk_Application: 3,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Phantom Pain',
    //       submitted: 'June 14, 2024',
    //       status: 'Declined',
    //     },
    //     {
    //       pk_Application: 4,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'FAITH',
    //       submitted: 'June 14, 2024',
    //       status: 'Pending',
    //     },
    //     {
    //       pk_Application: 5,
    //       organizationName: 'Earth Alliance',
    //       programName: 'Logos',
    //       submitted: 'June 14, 2024',
    //       status: 'Pending',
    //     },
    //     {
    //       pk_Application: 6,
    //       organizationName: 'Zodiac Alliance of Freedom Treaty',
    //       programName: 'Le Creuset Team',
    //       submitted: 'June 14, 2024',
    //       status: 'Accepted',
    //     },
    //   ]);
    // }, 2000);
  }, []);

  const headers = [
    {
      dataKey: 'organizationName',
      label: 'Organization',
      cellFormatter: (cellData, row) => {
        return <div style={{ fontWeight: 'bold' }}>{row.Organization.OrganizationName || ''}</div>;
      },
      width: 250,
    },
    { dataKey: 'programName', label: 'Program', width: 250 },
    { dataKey: 'submitted', label: 'Submitted', width: 250 },
    { dataKey: 'status', label: 'Status', width: 250 },
  ];

  // Effects
  useEffect(() => {
    if (isAuthenticated && kindeAuth && applicantDetails && applicantDetails.pk_Applicant) {
      getApplications();
    }
  }, [isAuthenticated, applicantDetails]);

  useEffect(() => {
    const pk_DepartmentFromURL = query.get('selectedApplication');

    if (pk_DepartmentFromURL) {
      const toShow = applications.find((app) => {
        return app.fk_Department == pk_DepartmentFromURL;
      });

      setSelectedApplication(toShow);
      if (toShow) {
        setShowApplicationDetailModal(true);
      }
    }
  }, [pathname, applications]);

  const getApplications = async () => {
    const { pk_Applicant } = applicantDetails || {};
    setIsLoading(true);
    const token = await getToken();
    fetchDataAgnostic('applicant/application/list', { pk_Applicant }, formatBearerToken(token))
      .then((res) => {
        setIsLoading(false);

        const newData = res.data.map((item) => {
          const {
            Department,
            IsRejected,
            IsRegistered,
            IsUnderReview,
            IsWithdrawn,
            IsSubmitted,
            TimestampOfApplicationSubmission,
          } = item;
          const { DepartmentName } = Department;

          let status = 'Not Submitted';

          if (IsSubmitted) {
            status = 'Submitted';
          } else if (IsRejected) {
            status = 'Rejected';
          } else if (IsRegistered) {
            status = 'Registered';
          } else if (IsUnderReview) {
            status = 'In Review';
          }

          return {
            programName: DepartmentName || '',
            ...item,
            status,
            submitted: moment.tz(TimestampOfApplicationSubmission, null).format('MMMM DD, YYYY'),
          };
        });

        setApplications(newData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('err: ', err);
      });
  };

  const getApplication = async (data) => {
    const { pk_ApplicationRecord, cb, pk_Season, pk_Department } = data;
    const token = await getToken();
    console.log('data: ', data);

    fetchDataAgnostic(
      // 'application/application',
      // 'department/application/applicant',
      'applicant/application',
      { pk_ApplicationRecord: pk_ApplicationRecord },
      formatBearerToken(token),
    )
      .then((res) => {
        console.log('getApplication res: ', res);
        if (!res.data) {
          console.log('throwing err!!');

          throw res;
        }
        const { ApplicantSubmission = {}, ApplicationRecord = {} } = res.data;
        const newApplicationDetails = { ...ApplicantSubmission, ...ApplicationRecord };

        applicationDetails.current = newApplicationDetails;
        setTimeout(() => {
          if (cb) {
            cb(null);
          }
        }, 300);
      })
      .catch((err) => {
        alert.error('Error fetching application details');
        console.log('getApplication err: ', err);
        if (cb) {
          cb(err);
        }
      });
  };

  const renderApplicationDetailModal = () => {
    const { programName = '', submitted = '', status = '' } = selectedApplication || {};
    return (
      <Modal isOpen={showApplicationDetailModal} centered style={{ maxWidth: '90vw' }}>
        <ModalHeader
          toggle={() => {
            setShowApplicationDetailModal(false);
            setSelectedApplication(null);
          }}
        >
          Application for {programName ? programName : 'Unnamed Program'}
        </ModalHeader>
        <ModalBody>
          <div style={style.simpleRow}>
            <div style={style.simpleColumn}>
              <ApplicationForm
                isEdit={selectedApplication && selectedApplication.status === 'Not Submitted'}
                applicationDetails={applicationDetails.current}
                selectedOpening={selectedApplication}
                applicantDetails={applicantDetails}
                onApply={() => {
                  setShowApplicationDetailModal(false);
                  setSelectedApplication(null);
                  getApplications();
                }}
              />
              {/* <div style={style.simpleRow}>Program: {programName}</div>
              <div style={style.simpleRow}>Submitted: {submitted}</div>
              <div style={style.simpleRow}>Status: {status}</div> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div style={{ ...style.simpleRow, marginTop: 50, justifyContent: 'center', width: 'calc(100vw - 30px)' }}>
      <div
        style={{
          ...style.simpleColumn,
          overflow: 'auto',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',

          width: 'auto',
        }}
      >
        <RezRateTable
          columns={headers}
          data={applications}
          onRowClick={(row) => {
            getApplication({
              pk_ApplicationRecord: row.pk_ApplicationRecord,
              pk_Department: row.fk_Department,
              pk_Season: row.fk_Season,
              cb: (err) => {
                if (err) {
                  return;
                }
                setSelectedApplication(row);
                setShowApplicationDetailModal(true);
              },
            });
            // setSelectedApplication(row);
            // setShowApplicationDetailModal(true);
          }}
          rowStyle={(row) => {
            const styleToReturn = {
              cursor: 'pointer',
            };

            if (
              selectedApplication &&
              selectedApplication.fk_Department == row.fk_Department &&
              selectedApplication.fk_ApplicationOpening == row.fk_ApplicationOpening
            ) {
              styleToReturn.backgroundColor = '#d9f5ff';
            }
            return styleToReturn;
          }}
          noDataMessage={isLoading ? <div>Loading...</div> : <div>No Data</div>}
        />
      </div>
      {renderApplicationDetailModal()}
    </div>
  );
};

export default ApplicationsPage;
