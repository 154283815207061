import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCog,
  faCopy,
  faEnvelope,
  faEnvelopeSquare,
  faExclamationCircle,
  faHatWizard,
  faLink,
  faMagic,
  faMinusCircle,
  faPlusCircle,
  faPrint,
  faRedo,
  faSyncAlt,
  faTrashAlt,
  faUser,
  faUsers,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkSettingsButton from 'Body/RezRATE/Common/LinkSettingsButton/LinkSettingsButton';
import { clone } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { deleteData, fetchDataAgnostic, putData } from '../../../../../Services/dataApi';
import {
  clearTimeslots,
  countScheduledCandidates,
  generateSchedule,
  generateSchedule2,
  getScheduleStartAndEndTime,
  setupSplitCountTrackers,
} from '../../../../../Services/schedule';
import ConfirmModal from '../ConfirmModal';
import CandidateDropDown from './CandidateDropDown';
import EvaluatorDropDown from './EvaluatorDropDown';
import PrintDropDown from './PrintDropDown';
import SettingsDropDown from './SettingsDropDown';
import style from './style.js';
import './TopBar.style.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

let candidateLimitTimeout = null;
const TopBar = ({
  addRowCustomEvent,
  allowInterviewLimit,
  candidates = {},
  columnTracker,
  dContext,
  evaluators = [],
  fetchSchedule,
  fireConfirmationForEditingPastSchedule,
  generateScheduleForEntireTable,
  generatedScheduleData,
  generateMode,
  interviewLimit,
  isAddingCandidate,
  isCloning,
  isEmbedded,
  isFetching,
  isGeneratingSchedule,
  isInPreviewMode,
  isSubmitting,
  rowTracker,
  scheduleData,
  scheduleID,
  setAllowInterviewLimit,
  setCandidateLimit,
  setGeneratedScheduleData,
  setInterviewLimit,
  setPendingCandidates,
  setShowCloneModal,
  setShowGenerateDataPanel,
  setShowUnsavedChangeModal,
  initializeTrackers,
  showCloneModal,
  submitGeneratedSchedule,
  toggleScheduleWizard,
  willEditThePast,
}) => {
  const [isOpenEvaluators, setIsOpenEvaluators] = useState(false);
  const [isOpenCandidates, setIsOpenCandidates] = useState(false);
  const [isOpenInterviewLimit, setIsOpenInterviewLimit] = useState(false);
  const [isOpenPrint, setIsOpenPrint] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isUpdatingCandidateLimit, setIsUpdatingCandidateLimit] = useState(false);
  const [isShowingSchedulePreview, setIsShowingSchedulePreview] = useState(false);

  const [candidatesWithNoScheduleSent, setCandidatesWithNoScheduleSent] = useState([]);

  const myScheduleData = useRef();

  const [showClearScheduleConfirmation, setShowClearScheduleConfirmation] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const history = useHistory();
  const scheduleDate =
    scheduleData && scheduleData.metaData && scheduleData.metaData.DateOfInterview
      ? moment(`${scheduleData.metaData.DateOfInterview || ''} ${scheduleData.metaData.StartTime}`)
      : null;

  const dateIsInThePast = scheduleDate ? scheduleDate.isBefore(moment.tz()) : false;

  const { metaData } = scheduleData || {};
  const { MainRoomURL, MainRoomURLDetails } = metaData || {};

  useEffect(() => {
    myScheduleData.current = clone(scheduleData);

    if (scheduleData && scheduleData.metaData) {
      const newLimit = scheduleData.metaData.CandidateLimit;

      setInterviewLimit(newLimit ? newLimit : null);

      if (newLimit == null) {
        setAllowInterviewLimit(false);
      }
    }
  }, [JSON.stringify(scheduleData)]);

  useEffect(() => {
    if (candidates) {
      processCandidates(candidates);
    }
  }, [candidates]);

  const updateCandidateLimit = (newCandidateLimit, callback) => {
    setIsUpdatingCandidateLimit(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: scheduleID,
            pk_Season: dContext.season.pk_Season,
          },
          {
            entry: {
              CandidateLimit: newCandidateLimit,
            },
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdatingCandidateLimit(false);
            if (result.data.error) {
              return;
            }

            fetchSchedule();

            // formatThenSetInterviewDates(result.data);
          })
          .catch((err) => {
            setIsUpdatingCandidateLimit(false);
          });
      })
      .catch((err) => {
        setIsUpdatingCandidateLimit(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const clearSchedule = (clearCustomEventsToo) => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'schedule/clear',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: scheduleID,
            pk_Season: dContext.season.pk_Season,
            includeCustomEvents: clearCustomEventsToo ? 1 : 0,
          },
          null,
          formatBearerToken(token),
        )
          .then((result) => {
            if (result.data.error) {
              return;
            }

            fetchSchedule();
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const processCandidates = () => {
    let candidatesNotSentSchedule = [];
    Object.keys(candidates).forEach((c) => {
      const candidate = candidates[c];
      if (!candidate.HasBeenSentSchedule) {
        candidatesNotSentSchedule.push(candidate);
      }
    });

    setCandidatesWithNoScheduleSent(candidatesNotSentSchedule);
  };

  const onEditCandidate = () => {};

  const onCalendarClick = () => {};

  const meetingInProgress = () => {
    let isMeetingInProgress = false;
    if (scheduleData) {
      const { StartTime, EndTime } = getScheduleStartAndEndTime(scheduleData);
      const now = moment(); //.format('MMM DD, YYYY hh:mm A');
      isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime); //isTimeRangeOverlap([StartTime, now], [now, EndTime]);
    }

    return isMeetingInProgress;
  };

  const renderCandidatesPopOver = () => {
    return (
      <Popover
        popperClassName="popperClassName"
        isOpen={isOpenCandidates}
        trigger="legacy"
        target="candidates_button"
        toggle={() => {
          if (isInPreviewMode() && !isOpenCandidates) {
            setShowUnsavedChangeModal(true);
            return;
          }
          setIsOpenCandidates(!isOpenCandidates);
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>Candidates</div>
        </PopoverHeader>
        <CandidateDropDown
          rowTracker={rowTracker}
          columnTracker={columnTracker}
          allowInterviewLimit={allowInterviewLimit}
          scheduleData={scheduleData}
          interviewLimit={interviewLimit}
          candidates={candidates}
          onCalendarClick={onCalendarClick}
          onEditCandidate={onEditCandidate}
          scheduleID={scheduleID}
        />
      </Popover>
    );
  };

  const renderEvaluatorsPopOver = () => {
    return (
      <Popover
        popperClassName="popperClassName"
        isOpen={isOpenEvaluators}
        trigger="legacy"
        target="evaluators_button"
        toggle={() => {
          if (isInPreviewMode() && !isOpenEvaluators) {
            setShowUnsavedChangeModal(true);
            return;
          }
          setIsOpenEvaluators(!isOpenEvaluators);
        }}
      >
        <PopoverHeader style={style.popOverHeader}>
          <div>Scheduled Evaluators</div>
        </PopoverHeader>

        <EvaluatorDropDown
          evaluators={evaluators}
          scheduleData={scheduleData}
          columnTracker={columnTracker}
          candidates={candidates}
        />
      </Popover>
    );
  };

  const renderInterviewLimitPopOver = () => {
    return (
      <Popover
        isOpen={isOpenInterviewLimit}
        target="interview_limit_button"
        trigger="legacy"
        toggle={async () => {
          if (isFetching) {
            return;
          }
          if (dateIsInThePast && !willEditThePast && fireConfirmationForEditingPastSchedule) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
          setIsOpenInterviewLimit(!isOpenInterviewLimit);
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>Interview Limit</div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <div style={style.simpleRow}>
            <CustomInput
              type="switch"
              id="allowInterviewLimitID"
              name="allowInterviewLimit"
              label="Limit Candidate Interviews"
              checked={allowInterviewLimit}
              onChange={(e) => {
                setAllowInterviewLimit(e.target.checked);
                if (!e.target.checked) {
                  updateCandidateLimit(null);
                } else {
                  updateCandidateLimit(1);
                }
              }}
            />
          </div>
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button
                  size={isEmbedded ? 'sm' : null}
                  disabled={!allowInterviewLimit || isUpdatingCandidateLimit}
                  onClick={() => {
                    if (interviewLimit > 0) {
                      const newCount = interviewLimit - 1 > 0 ? interviewLimit - 1 : 1;
                      clearTimeout(candidateLimitTimeout);
                      candidateLimitTimeout = setTimeout(() => {
                        setIsUpdatingCandidateLimit(true);
                        updateCandidateLimit(newCount);
                      }, 1000);

                      setInterviewLimit(newCount);
                      setCandidateLimit(newCount);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
              </InputGroupAddon>
              <Input
                type="number"
                value={interviewLimit || 0}
                style={style.popOverHeader}
                onChange={(e) => setInterviewLimit(e.target.value)}
                disabled
              />
              <InputGroupAddon addonType="append">
                <Button
                  size={isEmbedded ? 'sm' : null}
                  disabled={!allowInterviewLimit || isUpdatingCandidateLimit}
                  onClick={() => {
                    const newCount = interviewLimit + 1 > 0 ? interviewLimit + 1 : 1;

                    clearTimeout(candidateLimitTimeout);

                    candidateLimitTimeout = setTimeout(() => {
                      setIsUpdatingCandidateLimit(true);
                      updateCandidateLimit(newCount);
                    }, 1000);

                    setInterviewLimit(newCount);
                    setCandidateLimit(newCount);
                  }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderPrintPopOver = () => {
    return (
      <Popover
        placementPrefix="adminPrintPopover bs-popover"
        isOpen={isOpenPrint}
        target="print_button"
        trigger="legacy"
        flip={false}
        placement="right"
        toggle={(e) => {
          if (isShowingSchedulePreview) {
            return;
          }
          if (isInPreviewMode() && !isOpenPrint) {
            setShowUnsavedChangeModal(true);
            return;
          }
          setIsOpenPrint(!isOpenPrint);
        }}
      >
        <PopoverHeader>
          <div style={style.popOverHeader}>{scheduleDate ? scheduleDate.format('MMM DD, YYYY') : 'No Date'}</div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <div>
            <PrintDropDown
              scheduleID={scheduleID}
              setIsShowingSchedulePreview={setIsShowingSchedulePreview}
              forcedScheduleData={scheduleData}
            />
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderPopOvers = () => {
    return (
      <>
        {renderEvaluatorsPopOver()}
        {renderCandidatesPopOver()}
        {renderInterviewLimitPopOver()}
        {renderPrintPopOver()}
      </>
    );
  };

  const renderModals = () => {
    return (
      <>
        <ConfirmModal isOpen={false} message={'Updating . . .'} />
        <ConfirmModal
          modalStyle={{ minWidth: '30%' }}
          isOpen={showClearScheduleConfirmation}
          title={'Clear Schedule'}
          message={'How do you want to proceed?'}
          buttonChoices={
            <>
              <Button
                size={isEmbedded ? 'sm' : null}
                color="danger"
                style={{ ...style.topNavButton, backgroundColor: null, paddingLeft: 10 }}
                onClick={() => {
                  setShowClearScheduleConfirmation(false);
                }}
              >
                <div style={style.buttonLabel}>Cancel</div>
              </Button>
              <Button
                size={isEmbedded ? 'sm' : null}
                style={{ ...style.topNavButton, paddingLeft: 10 }}
                onClick={() => {
                  const newData = clearTimeslots(JSON.parse(JSON.stringify(scheduleData.body)), false, true);
                  clearSchedule();
                  // submitGeneratedSchedule(newData);
                  setShowClearScheduleConfirmation(false);
                }}
              >
                <div style={style.buttonLabel}>Clear Candidates</div>
              </Button>
              <Button
                size={isEmbedded ? 'sm' : null}
                style={{ ...style.topNavButton, paddingLeft: 10 }}
                onClick={() => {
                  const newData = clearTimeslots(JSON.parse(JSON.stringify(scheduleData.body)), true, true);
                  const { newSchedule, eventsToDelete } = newData;
                  clearSchedule(true);
                  // submitGeneratedSchedule(newSchedule.body);

                  // Add logic to delete all events in eventsToDelete

                  // eventsToDelete.forEach((pk_Timeslot) => {
                  //   addRowCustomEvent({
                  //     pk_Timeslot: pk_Timeslot,
                  //     CustomDurationInMinutes: null,
                  //     CustomMeetingDescription: null,
                  //     CustomMeetingTitle: null,
                  //     CustomMeetingUrl: null,
                  //     CustomPassingDurationInMinutes: null,
                  //     VirtualRoomType: null,
                  //   });
                  // });
                  setShowClearScheduleConfirmation(false);
                }}
              >
                <div style={style.buttonLabel}>Clear All</div>
              </Button>
            </>
          }
        />
      </>
    );
  };

  const renderIndicator = () => {
    let indicatorMessage = 'Saved!';
    let icon = <FontAwesomeIcon icon={faCheckCircle} />;

    if (isSubmitting) {
      icon = <FontAwesomeIcon icon={faCloudUploadAlt} />;
    }

    if (isFetching) {
      icon = <FontAwesomeIcon icon={faCloudDownloadAlt} />;
    }

    if (isGeneratingSchedule) {
      icon = <FontAwesomeIcon icon={faSyncAlt} />;
      indicatorMessage = 'Processing Schedule. . .';
    }

    if (isCloning) {
      icon = <FontAwesomeIcon icon={faCopy} />;
      indicatorMessage = 'Cloning Schedule. . .';
    } else {
      if (isSubmitting) {
        if (isAddingCandidate) {
          indicatorMessage = 'Adding Candidate. . .';
        } else {
          indicatorMessage = 'Submitting changes. . .';
        }
      }
    }

    if (isFetching) {
      icon = <FontAwesomeIcon icon={faSyncAlt} />;
      indicatorMessage = 'Reloading data. . .';
    }

    return (
      <div style={style.flexStartRow}>
        <div style={{ verticalAlign: 'middle', color: indicatorMessage === 'Saved!' ? 'green' : null }}>
          <div style={{ fontWeight: 500 }}>
            {icon} {indicatorMessage}
          </div>
        </div>
      </div>
    );
  };

  const { headers = [], body = [] } = scheduleData || {};
  const validEvaluators = [];

  headers.forEach((h) => {
    const evs = (h.Evaluators && h.Evaluators.Evaluators) || [];
    evs.forEach((ev) => {
      if (!validEvaluators.includes(ev.pk_User)) {
        validEvaluators.push(ev.pk_User);
      }
    });
  });

  const candidatesAllGood = candidatesWithNoScheduleSent && candidatesWithNoScheduleSent.length <= 0;

  const status = candidatesAllGood
    ? 'All candidates have been sent their schedules!'
    : `${candidatesWithNoScheduleSent.length} ${
        candidatesWithNoScheduleSent.length > 1 ? 'candidates have' : 'candidate has'
      } not
  been sent their schedule yet!`;
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
        {' '}
        <span>
          <span
            style={{ cursor: 'pointer', fontWeight: 'bold', color: '#0275d8', marginRight: 5 }}
            onClick={() => {
              history.push('/administrator');
            }}
          >
            {' '}
            Sessions{' '}
          </span>
          <span style={{ color: '#7a7979', fontWeight: 'bold', marginRight: 5 }}> / </span>
          <span style={{ color: '#7a7979', fontWeight: 'bold' }}> Scheduler</span>
        </span>
      </div>
      <div style={{ ...style.spacedBetweenRow, minHeight: 70 }}>
        <div style={{ ...style.simpleColumn, minWidth: 350, marginBottom: 0 }}>
          <div style={style.spacedBetweenRow}>
            <div style={{ ...style.simpleColumn, marginBottom: 0 }}>
              <div style={{ ...style.scheduleTitleDate, minWidth: 180 }}>
                {scheduleDate ? scheduleDate.utcOffset(0, true).format('MMM DD, YYYY') : 'No Date'}
              </div>
              <div style={{ color: '#858585' }}>
                {scheduleData && scheduleData.metaData && scheduleData.metaData.Label
                  ? scheduleData.metaData.Label
                  : ''}
              </div>
            </div>

            <div style={{ ...style.simpleColumn, marginBottom: 0, justifyContent: 'center', alignItems: 'center' }}>
              <div style={style.flexEndRow}>
                <Button size={isEmbedded ? 'sm' : null} id="print_button" style={style.printButton}>
                  <div style={style.simpleRow}>
                    <FontAwesomeIcon icon={faPrint} />
                  </div>
                </Button>
                <LinkSettingsButton
                  id={'schedule'}
                  btnSize={isEmbedded ? 'sm' : null}
                  MainRoomURL={MainRoomURL}
                  MainRoomURLDetails={MainRoomURLDetails}
                  scheduleDate={scheduleDate}
                  scheduleData={scheduleData}
                  scheduleID={scheduleID}
                  dContext={dContext}
                  isInPreviewMode={isInPreviewMode}
                  setShowUnsavedChangeModal={setShowUnsavedChangeModal}
                  onUpdate={fetchSchedule}
                />
                {/* <div id="settings_button_container">
                  <Button
                    size={isEmbedded ? 'sm' : null}
                    id="settings_button"
                    color={MainRoomURL ? 'primary' : 'danger'}
                    style={{ ...style.printButton, backgroundColor: null }}
                    // disabled={dateIsInThePast && !willEditThePast}
                  >
                    <div style={style.simpleRow}>
                      <FontAwesomeIcon icon={faLink} />
                      <div style={{ position: 'relative' }}>
                        <FontAwesomeIcon
                          icon={faCog}
                          style={{ position: 'absolute', fontSize: 10, left: -6, top: 2, color: 'white' }}
                        />
                      </div>
                    </div>
                  </Button>
                </div>
                {!MainRoomURL ? (
                  <UncontrolledTooltip target="settings_button_container">Missing All Day URL!</UncontrolledTooltip>
                ) : null} */}

                {headers.length <= 0 && body.length <= 1 ? (
                  <Button
                    size={isEmbedded ? 'sm' : null}
                    id="wizard"
                    style={style.printButton}
                    onClick={() => {
                      toggleScheduleWizard();
                    }}
                  >
                    <div style={style.simpleRow}>
                      <FontAwesomeIcon icon={faHatWizard} />
                    </div>
                  </Button>
                ) : null}

                {headers.length <= 0 && body.length <= 1 ? (
                  <UncontrolledTooltip target="wizard">Summon Schedule Wizard!</UncontrolledTooltip>
                ) : null}
              </div>
            </div>
          </div>
          {renderIndicator()}
        </div>
        <div style={style.simpleColumn}>
          <div style={{ ...style.simpleRow, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            <div style={style.simpleColumn}>
              <Button
                size={isEmbedded ? 'sm' : null}
                id="evaluators_button"
                style={style.topNavButton}
                onClick={async (e) => {
                  // if (dateIsInThePast && !willEditThePast) {
                  //   const continueProcess = await fireConfirmationForEditingPastSchedule();
                  //   if (!continueProcess) {
                  //     return;
                  //   }
                  // }
                }}
              >
                <div style={style.spacedBetweenRow}>
                  <FontAwesomeIcon icon={faUser} style={style.buttonIcon} />
                  <div style={style.buttonLabel}> {validEvaluators.length} Evaluators</div>
                </div>
              </Button>
            </div>
            <div style={style.simpleColumn}>
              <Button
                size={isEmbedded ? 'sm' : null}
                id="candidates_button"
                style={style.topNavButton}
                onClick={async (e) => {
                  // if (dateIsInThePast && !willEditThePast) {
                  //   const continueProcess = await fireConfirmationForEditingPastSchedule();
                  //   if (!continueProcess) {
                  //     return;
                  //   }
                  // }
                }}
              >
                <div style={style.spacedBetweenRow}>
                  <FontAwesomeIcon icon={faUsers} style={style.buttonIcon} />
                  <div style={{ ...style.buttonLabel, marginRight: 10 }}>
                    {Object.keys(candidates).length} Candidates
                  </div>
                  <FontAwesomeIcon
                    id="candidates_status"
                    icon={candidatesAllGood ? faCheckCircle : faExclamationCircle}
                    style={{
                      ...style.buttonIcon,
                      color: candidatesAllGood ? '#35911f' : 'red',
                      border: '2px solid #f2f2f2',
                      borderRadius: 20,
                      backgroundColor: '#f2f2f2',
                    }}
                  />
                  <UncontrolledTooltip target="candidates_status">{status}</UncontrolledTooltip>
                </div>
              </Button>
            </div>
            <div style={style.simpleColumn}>
              <Button
                disabled={isFetching || !scheduleData}
                size={isEmbedded ? 'sm' : null}
                id="interview_limit_button"
                style={{ ...style.topNavButton }}
                onClick={() => {}}
                // disabled={dateIsInThePast && !willEditThePast}
              >
                <div style={style.buttonLabel}>
                  {allowInterviewLimit
                    ? `Limit Candidates to ${interviewLimit} ${interviewLimit > 1 ? 'Interviews' : 'Interview'}`
                    : 'Candidate Limits disabled'}
                </div>
              </Button>
            </div>
            <div style={style.simpleColumn}>
              <Button
                size={isEmbedded ? 'sm' : null}
                // disabled={dateIsInThePast && !willEditThePast}
                style={{ ...style.topNavButton }}
                onClick={async () => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }

                  const scheduledCandidates = countScheduledCandidates(Object.values(candidates));

                  if (meetingInProgress() && scheduledCandidates > 0) {
                    Swal.fire(
                      'Meeting already in progress!',
                      'Sorry, this schedule is currently in progress. You will need to manually make any necessary adjustments. Proceed with caution. Contact support if you need advice for best practices.',
                      'error',
                    );
                    return;
                  }

                  if (Object.keys(candidates).length <= 0) {
                    Swal.fire('No Candidates!', 'There are no candidates to make a schedule with!', 'info');
                    return;
                  }

                  const newScheduleBody = generateSchedule2(clone(clone(myScheduleData.current)), candidates, {
                    interviewLimit,
                    mode: generateMode,
                    allowInterviewLimit,
                    clearTable: true,
                  });

                  const newScheduleData = JSON.parse(JSON.stringify(scheduleData));
                  newScheduleData.body = newScheduleBody.newBody;
                  newScheduleData.interviewCountTracker = newScheduleBody.interviewCountTracker;
                  newScheduleData.candidatesWithMissingInterviews = newScheduleBody.candidatesWithMissingInterviews;

                  setGeneratedScheduleData(newScheduleData);
                  setShowGenerateDataPanel(true);
                  if (setPendingCandidates) {
                    setPendingCandidates(newScheduleBody.pendingItems);
                  }
                  initializeTrackers(newScheduleData);
                }}
              >
                <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                  <FontAwesomeIcon icon={generatedScheduleData ? faRedo : faMagic} style={style.buttonIcon} />
                  <div style={style.buttonLabel}>
                    {generatedScheduleData ? 'Regenerate Schedule' : 'Generate Schedule'}
                  </div>
                </div>
              </Button>
            </div>
            {/* <div style={style.simpleColumn}>
              <Button
                size={isEmbedded ? 'sm' : null}
                // disabled={dateIsInThePast && !willEditThePast}
                color="primary"
                style={{ ...style.topNavButton, backgroundColor: null }}
                onClick={() => {
                  setShowCloneModal(!showCloneModal);
                }}
              >
                <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                  <FontAwesomeIcon icon={faCopy} style={style.buttonIcon} />
                  <div style={style.buttonLabel}>Clone Schedule</div>
                </div>
              </Button>
            </div> */}
            <div style={style.simpleColumn}>
              <Button
                disabled={isFetching || !scheduleData}
                size={isEmbedded ? 'sm' : null}
                // disabled={dateIsInThePast && !willEditThePast}
                color="danger"
                style={{ ...style.topNavButton, backgroundColor: null }}
                onClick={async () => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  setShowClearScheduleConfirmation(true);
                  // setGeneratedScheduleData(null);
                  // submitGeneratedSchedule(clearTimeslots(JSON.parse(JSON.stringify(scheduleData.body))));
                }}
              >
                <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                  <FontAwesomeIcon icon={faTrashAlt} style={style.buttonIcon} />
                  <div style={style.buttonLabel}>Clear Schedule</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        {renderPopOvers()}
        {renderModals()}
      </div>
    </>
  );
};

export default TopBar;
