import React, { useState, useEffect } from 'react';
import tinycolor from 'tinycolor2';

/**
 * RezRateBanner - 100% width banner that displays a message.
 */
const RezRateBanner = (props) => {
  const {
    message,
    backgroundColor,
    textAlign = 'center',
    paddingTop = 5,
    paddingBottom = 5,
    fontSize,
    onClick,
  } = props;
  const textColor = props.textColor
    ? props.textColor
    : backgroundColor
    ? tinycolor(backgroundColor).isLight()
      ? '#000000'
      : '#ffffff'
    : '#000000';
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(!toggle);

    // Your effect logic here
  }, [props]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        textAlign,
        color: textColor,
        paddingTop,
        paddingBottom,
        backgroundColor,
        justifyContent: 'center',
        fontSize,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <span>{message || <br />}</span>
    </div>
  );
};

export default RezRateBanner;
