import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './AdminApplicants.style';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faEye,
  faHourglassHalf,
  faMinusCircle,
  faPaperclip,
  faRedoAlt,
  faSquareCheck,
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import { fetchDataAgnostic, postDataAgnostic } from 'Services/dataApi';
import { DepartmentContext } from 'DepartmentWrapper';
import { formatBearerToken } from 'Common.functions';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import ApplicationForm from 'Body/RezRATE/Body/DirectApplication/ApplicantProfile/ApplyPage/ApplicationForm/ApplicationForm';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';
import { clone, filter, set } from 'lodash';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

function AdminApplicants() {
  const [applicants, setApplicants] = useState([]);
  const [departmentApplicantData, setDepartmentApplicantData] = useState();
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [baseUrl, setBaseUrl] = useState('');

  const [applicantsImportedCount, setApplicantsImportedCount] = useState(0);
  const [applicantsRejectedCount, setApplicantsRejectedCount] = useState(0);
  const [applicantsPendingReviewCount, setApplicantsPendingReviewCount] = useState(0);

  const [sortBy, setSortBy] = useState('LastName');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterBy, setFilterBy] = useState('');
  const [filteredApplicants, setFilteredApplicants] = useState([]);

  const [isFetchingApplicants, setIsFetchingApplicants] = useState(false);
  const [isFetchingDepartmentApplicantData, setIsFetchingDepartmentApplicantData] = useState(false);
  const [isGeneratingNewCode, setIsGeneratingNewCode] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isImportingApplicants, setIsImportingApplicants] = useState(false);
  const [isRejectingApplicants, setIsRejectingApplicants] = useState(false);

  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [applicantToPreview, setApplicantToPreview] = useState();
  const [showApplicantPreview, setShowApplicantPreview] = useState(false);

  const applicantsRef = useRef();
  const headersRef = useRef([
    {
      label: 'Select',
      dataKey: 'Select',
      width: 50,
      headerFormatter: (headerData) => {
        const { IsSelectedAll } = headerData || {};
        return (
          <FontAwesomeIcon
            icon={IsSelectedAll ? faSquareCheck : faSquare}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const newValue = !IsSelectedAll;
              const newApplicants = clone(
                applicantsRef.current.map((applicant) => {
                  applicant.isSelected = newValue;
                  return applicant;
                }),
              );
              setApplicants(newApplicants);
              updateSelectAll(newValue);
            }}
          />
        );
      },
      cellFormatter: (cellData, row) => {
        const { isSelected } = row;
        return (
          <div>
            <FontAwesomeIcon
              icon={isSelected ? faSquareCheck : faSquare}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const { pk_ApplicationRecord } = row;

                const existingRow = applicantsRef.current.find(
                  (applicant) => applicant.pk_ApplicationRecord === pk_ApplicationRecord,
                );

                if (existingRow.isSelected) {
                  setApplicants(() => {
                    return applicantsRef.current.map((applicant) => {
                      if (applicant.pk_ApplicationRecord === pk_ApplicationRecord) {
                        return { ...applicant, isSelected: false };
                      }
                      return applicant;
                    });
                  });

                  updateSelectAll(false);
                } else {
                  let newIsSelectedAll = true;
                  setApplicants((prevData) => {
                    return applicantsRef.current.map((applicant, i) => {
                      if (applicant.pk_ApplicationRecord === pk_ApplicationRecord) {
                        return { ...applicant, isSelected: true };
                      }

                      if (!applicant.isSelected) {
                        newIsSelectedAll = false;
                      }

                      if (i === applicantsRef.current.length - 1) {
                        updateSelectAll(newIsSelectedAll);
                      }
                      return applicant;
                    });
                  });
                }
              }}
            />
            {/* {row.isSelected ? 'TRUE' : 'FALSE'} */}
          </div>
        );
      },
    },
    {
      label: 'Status',
      dataKey: 'Status',
      width: 80,
      cellFormatter: (cellData, row) => {
        const { IsRejected, IsInvited, IsUnderReview, pk_ApplicationRecord, IsSubmitted } = row;
        const statusId = `status_${pk_ApplicationRecord}`;
        // return (
        //   <div style={style.simpleRow}>
        //     <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
        //     <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
        //     <FontAwesomeIcon icon={faHourglassHalf} style={{ color: '#FFA500' }} />
        //     <FontAwesomeIcon icon={faMinusCircle} style={{ color: '#808080' }} />
        //   </div>
        // );
        if (IsRejected) {
          return (
            <>
              <FontAwesomeIcon id={statusId} icon={faTimesCircle} style={{ color: 'red', cursor: 'pointer' }} />
              <UncontrolledTooltip target={`${statusId}`}>Your application has been rejected.</UncontrolledTooltip>
            </>
          );
        } else if (IsInvited) {
          return (
            <>
              <FontAwesomeIcon id={statusId} icon={faCheckCircle} style={{ color: 'green', cursor: 'pointer' }} />
              <UncontrolledTooltip target={`${statusId}`}>Your application has been accepted.</UncontrolledTooltip>
            </>
          );
        } else if (IsUnderReview) {
          return (
            <>
              <FontAwesomeIcon id={statusId} icon={faHourglassHalf} style={{ color: '#FFA500', cursor: 'pointer' }} />
              <UncontrolledTooltip target={`${statusId}`}>Your application is under review.</UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon id={statusId} icon={faMinusCircle} style={{ color: '#808080', cursor: 'pointer' }} />
              <UncontrolledTooltip target={`${statusId}`}>Your application has been submitted.</UncontrolledTooltip>
            </>
          );
        }
      },
    },
    {
      label: 'Last Name',
      dataKey: 'LastName',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'FirstName',
      width: 200,
    },
    {
      label: 'Email',
      dataKey: 'Email',
      width: 200,
    },
    {
      label: 'Date Applied',
      dataKey: 'TimestampOfApplicationSubmission',
      width: 150,
      cellFormatter: (TimestampOfApplicationSubmission) => {
        return moment(TimestampOfApplicationSubmission).format('MM/DD/YYYY');
      },
    },
    {
      label: 'Actions',
      dataKey: 'action',
      width: 80,
      cellFormatter: (cellData, row) => {
        const { Applicant, pk_ApplicationRecord } = row;
        const statusId = `action_preview_${pk_ApplicationRecord}`;

        return (
          <>
            <Button
              id={statusId}
              color="primary"
              size="sm"
              onClick={() => {
                console.log('row: ', row);

                setApplicantToPreview(Applicant);
                setShowApplicantPreview(true);
              }}
            >
              <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} />
            </Button>

            <UncontrolledTooltip target={`${statusId}`}>Preview Application</UncontrolledTooltip>
          </>
        );
      },
    },
  ]);

  const { getTokenSilently } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  useEffect(() => {
    fetchApplicants();
    fetchDepartmentApplicantData();
    setColumnData(headersRef.current);
    const { protocol, hostname, port } = window.location;
    const newBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    setBaseUrl(newBaseUrl);
    // generateFakeApplicants();
  }, []); // Empty dependency array means the effect runs only once, similar to componentDidMount

  useEffect(() => {
    applicantsRef.current = applicants;
  }, [applicants]);

  useEffect(() => {
    if (applicantsRef.current) {
      setSelectedApplicants(applicantsRef.current.filter((applicant) => applicant.isSelected));
    }
  }, [applicantsRef.current]);

  useEffect(() => {
    if (filterBy === 'All') {
      setFilteredApplicants(applicants);
    } else {
      setFilteredApplicants(filterApplicants(applicants));
    }
  }, [filterBy, applicants]);

  const fetchApplicants = () => {
    setIsFetchingApplicants(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/application/applicant/list',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            console.log('fetchApplicants res: ', res);

            setIsFetchingApplicants(false);
            setApplicants(processApplicants(res.data));
          })
          .catch((err) => {
            setIsFetchingApplicants(false);
            console.log('fetchApplicants err: ', err);
          });
      })
      .catch((err) => {
        setIsFetchingApplicants(false);
        console.log('getToken err: ', err);
      });
  };

  const fetchDepartmentApplicantData = () => {
    setIsFetchingDepartmentApplicantData(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/application/list',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            console.log('fetchDepartmentApplicantData res: ', res);
            setDepartmentApplicantData(res.data);
          })
          .catch((err) => {
            console.log('fetchDepartmentApplicantData err: ', err);
          })
          .finally(() => {
            setIsFetchingDepartmentApplicantData(false);
          });
      })
      .catch((err) => {
        setIsFetchingDepartmentApplicantData(false);
        console.log('getToken err: ', err);
      });
  };

  const filterApplicants = (applicantsToFilter) => {
    console.log('filterApplicants! filterBy: ', filterBy);

    const newApplicants = applicantsToFilter.filter((applicant) => {
      const { IsRejected, IsImported, IsUnderReview } = applicant.Applicant;
      if (filterBy === 'All' || !filterBy) {
        return true;
      } else if (filterBy === 'Imported') {
        return IsImported;
      } else if (filterBy === 'Rejected') {
        return IsRejected;
      } else {
        return IsUnderReview;
      }
    });

    return newApplicants;
  };

  const sortApplicants = (applicantsToSort) => {
    const newApplicants = applicantsToSort.sort((a, b) => {
      if (sortDirection === 'asc') {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      } else {
        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
        if (a[sortBy] < b[sortBy]) {
          return 1;
        }
        return 0;
      }
    });

    return newApplicants;
  };

  const updateSelectAll = (newValue) => {
    // Make function for setting selectAll
    const newColumnData = clone(headersRef.current);
    const newColumn0 = clone(newColumnData[0]);

    newColumn0.IsSelectedAll = newValue;

    newColumnData[0] = newColumn0;
    setIsSelectedAll(newValue);

    setColumnData(newColumnData);
  };

  const generateNewCode = () => {
    setIsGeneratingNewCode(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/application/code',
          { pk_Department: dContext.department.pk_Department },
          null,
          formatBearerToken(token),
        )
          .then((result) => {
            console.log('app code result: ', result);
            setIsGeneratingNewCode(false);
            alert.success('New code generated successfully');
            fetchDepartmentApplicantData();
          })
          .catch((err) => {
            console.log('err: ', err);
            setIsGeneratingNewCode(false);
            alert.error('Failed to generate new code');
          });
      })
      .catch((err) => {
        setIsGeneratingNewCode(false);
        console.log('getToken err: ', err);
      });
  };

  const generateFakeApplicants = () => {
    const fakeApplicants = [];
    for (let i = 0; i < 10; i++) {
      const applicant = {
        Applicant: {
          LastName: `Last Name ${i}`,
          FirstName: `First Name ${i}`,
          Email: `email${i}@example.com`,
          dateApplied: new Date().toISOString(),
        },
      };
      fakeApplicants.push(applicant);
    }
    setApplicants(fakeApplicants);
  };

  const processApplicants = (applicantsToProcess) => {
    let newApplicantsRejectedCount = 0;
    let newApplicantsImportedCount = 0;
    let newApplicantsPendingReviewCount = 0;
    console.log('applicantsToProcess: ', applicantsToProcess);

    const newApplicants = applicantsToProcess
      .map((applicant) => {
        const { IsRejected, IsInvited, IsUnderReview } = applicant;
        if (IsRejected) {
          newApplicantsRejectedCount++;
        } else if (IsInvited) {
          newApplicantsImportedCount++;
        } else if (IsUnderReview) {
          newApplicantsPendingReviewCount++;
        }

        return { ...applicant.Applicant, ...applicant };
      })
      .sort((a, b) => {
        const aText = `${a.LastName || ''}, ${a.FirstName || ''}`;
        const bText = `${b.LastName || ''}, ${b.FirstName || ''}`;

        if (aText < bText) {
          return -1;
        }
        if (aText > bText) {
          return 1;
        }
        return 0;
      });
    setApplicantsImportedCount(newApplicantsImportedCount);
    setApplicantsRejectedCount(newApplicantsRejectedCount);
    setApplicantsPendingReviewCount(newApplicantsPendingReviewCount);

    return newApplicants;
  };

  const importApplicants = () => {
    setIsImportingApplicants(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/application/invite',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            pk_Applicants: selectedApplicants.map((applicant) => {
              return applicant.fk_Applicant;
            }),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            console.log('res: ', res);
            alert.success('Applicants imported successfully');
            fetchApplicants();
            setSelectedApplicants([]);
          })
          .catch((err) => {
            alert.error('Failed to import applicants!');
          })
          .finally(() => {
            setIsImportingApplicants(false);
          });
      })
      .catch((err) => {
        console.log('err: ', err);
        setIsImportingApplicants(false);
      });
  };

  const rejectApplicants = () => {
    setIsRejectingApplicants(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/application/reject',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            pk_Applicants: selectedApplicants.map((applicant) => {
              return applicant.fk_Applicant;
            }),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            console.log('res: ', res);
            alert.success(`Applicant${selectedApplicants.length > 1 ? 's' : ''}rejected successfully`);
            fetchApplicants();
            setSelectedApplicants([]);
          })
          .catch((err) => {
            alert.error(`Failed to reject applicant${selectedApplicants.length > 1 ? 's' : ''}!`);
          })
          .finally(() => {
            setIsRejectingApplicants(false);
          });
      })
      .catch((err) => {
        console.log('err: ', err);
        alert.error(`Failed to reject applicant${selectedApplicants.length > 1 ? 's' : ''}!`);
        setIsRejectingApplicants(false);
      });
  };

  const renderFormPreview = () => {
    return (
      <Modal
        isOpen={showFormPreview}
        toggle={() => setShowFormPreview(!showFormPreview)}
        size="lg"
        style={{ minWidth: '90vw' }}
      >
        <ModalHeader toggle={() => setShowFormPreview(!showFormPreview)}>Application Form Preview</ModalHeader>

        <ModalBody>
          <ApplicationForm isReadOnly={true} />
        </ModalBody>
      </Modal>
    );
  };

  const renderFormPreviewForApplicant = () => {
    const { LastName, FirstName } = applicantToPreview || {};
    const displayName = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

    return (
      <Modal
        isOpen={showApplicantPreview}
        toggle={() => setShowApplicantPreview(!showApplicantPreview)}
        size="lg"
        style={{ minWidth: '90vw' }}
      >
        <ModalHeader toggle={() => setShowApplicantPreview(!showApplicantPreview)}>
          Application Form Preview for {displayName}
        </ModalHeader>

        <ModalBody>
          <ApplicationForm isReadOnly={true} applicantDetails={applicantToPreview} />
        </ModalBody>
      </Modal>
    );
  };

  const { Department = {} } = departmentApplicantData || {};

  return (
    <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
      <div style={{ ...style.simpleColumn }}>
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <div style={{ ...style.simpleColumn, width: '90vw' }}>
            <div style={{ ...style.simpleRow, width: '100%' }}>
              <Card style={{ width: '100%' }}>
                <CardBody>
                  <div style={{ ...style.simpleColumn, width: '100%' }}>
                    <div style={{ ...style.simpleRow, justifyContent: 'space-between', padding: 2 }}>
                      <div
                        style={{
                          ...style.simpleColumn,
                          width: 'auto',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Application URL
                      </div>
                      <div style={{ ...style.simpleColumn, width: 'auto' }}>
                        <div style={{ ...style.simpleRow, justifyContent: 'space-between', alignItems: 'center' }}>
                          <Button
                            color="primary"
                            size="sm"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              setShowFormPreview(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} /> Preview Application Form
                          </Button>

                          <Button
                            color="success"
                            size="sm"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${baseUrl}/applicant/profile/new_application?applicationCode=${Department.ApplicantCode}`,
                              );
                              alert.success('URL copied to clipboard');
                            }}
                          >
                            <FontAwesomeIcon icon={faCopy} /> Copy
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <Button
                        disabled={isGeneratingNewCode}
                        color="danger"
                        size="sm"
                        onClick={() => {
                          Swal.fire({
                            title: 'Are you sure?',
                            text:
                              'This will generate a new code and invalidate the current one. Are you sure you want to proceed?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                          }).then((result) => {
                            if (result.isConfirmed) {
                              generateNewCode();
                            }
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faRedoAlt} />
                      </Button>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      size="sm"
                      disabled={true}
                      value={`${baseUrl}/applicant/profile/new_application?applicationCode=${Department.ApplicantCode}`}
                      style={{ width: 300 }}
                    />
                  </InputGroup>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ ...style.simpleRow, fontSize: 14, marginTop: 5, justifyContent: 'center' }}>
          <div style={{ ...style.simpleColumn, width: '90vw' }}>
            <Card style={{ width: '100%' }}>
              <CardBody>
                <div style={{ ...style.simpleColumn, width: 'auto', flexWrap: 'wrap' }}>
                  <div style={{ ...style.simpleRow, fontWeight: 'bold', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <div style={{ ...style.simpleColumn, width: 80, alignItems: 'center', justifyContent: 'center' }}>
                      Applicants
                    </div>{' '}
                    <div style={{ ...style.simpleColumn, width: 100, marginLeft: 5 }}>
                      {' '}
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          setFilterBy('All');
                        }}
                      >
                        {' '}
                        Total: <Badge color="dark">{applicants.length}</Badge>{' '}
                      </Button>
                    </div>
                    <div style={{ ...style.simpleColumn, width: 'auto', marginLeft: 5 }}>
                      {' '}
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          setFilterBy('Imported');
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{ color: 'green', cursor: 'pointer', marginRight: 5 }}
                        />
                        Application Accepted: <Badge color="dark">{applicantsImportedCount}</Badge>{' '}
                      </Button>
                    </div>
                    <div style={{ ...style.simpleColumn, width: 140, marginLeft: 5 }}>
                      {' '}
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          setFilterBy('Rejected');
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ color: 'red', cursor: 'pointer', marginRight: 5 }}
                        />
                        Rejected: <Badge color="dark">{applicantsRejectedCount}</Badge>{' '}
                      </Button>
                    </div>
                    <div style={{ ...style.simpleColumn, width: 160, marginLeft: 5 }}>
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          setFilterBy('Pending Review');
                        }}
                      >
                        <FontAwesomeIcon icon={faHourglassHalf} style={{ color: '#FFA500', marginRight: 5 }} />
                        Pending Review: <Badge color="dark">{applicantsPendingReviewCount}</Badge>{' '}
                      </Button>
                    </div>
                  </div>
                  <div
                    id="tableContainer"
                    style={{
                      ...style.simpleRow,
                      marginTop: 10,
                      justifyContent: 'center',
                      // overflow: 'auto',
                      width: '100%',
                      // overflowX: 'auto',
                    }}
                  >
                    <div
                      style={{ ...style.simpleColumn, width: 'auto', justifyContent: 'flex-start', overflow: 'auto' }}
                    >
                      <RezRateTable
                        data={filteredApplicants || []}
                        tableBodyHeight={`calc(100vh - 540px)`}
                        columns={columnData || []}
                        noDataMessage={isFetchingApplicants ? 'Loading. . .' : 'No Data'}
                        onRowClick={(row) => {
                          console.log('row: ', row);
                        }}
                        rowStyle={(rowData) => {
                          const { isSelected } = rowData;
                          if (isSelected) {
                            return {
                              backgroundColor: '#ADD8E6',
                            };
                          } else {
                            return {};
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      ...style.simpleRow,
                      justifyContent: 'space-between',
                      marginTop: 10,
                      overflow: 'auto',
                      width: '100%',
                    }}
                  >
                    <Button
                      size="sm"
                      color="danger"
                      style={{ width: 100 }}
                      disabled={selectedApplicants.length <= 0 || isImportingApplicants}
                      onClick={() => {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: `This will reject the ${selectedApplicants.length} ${' '} selected applicant${
                            selectedApplicants.length > 1 ? 's' : ''
                          }. Are you sure you want to proceed?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          cancelButtonText: 'No',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            rejectApplicants();
                          }
                        });
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      style={{ width: 200 }}
                      disabled={selectedApplicants.length <= 0 || isImportingApplicants}
                      onClick={() => {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: `This will import the ${selectedApplicants.length}${' '}selected applicant${
                            selectedApplicants.length > 1 ? 's' : ''
                          }. Are you sure you want to proceed?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          cancelButtonText: 'No',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            importApplicants();
                          }
                        });
                      }}
                    >
                      Accept Application
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        {renderFormPreview()}
        {renderFormPreviewForApplicant()}
      </div>
    </div>
  );
}

export default AdminApplicants;
