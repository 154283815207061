import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import Demo from './Body/Demo/Demo';
import UserHeaderContainer from './Body/RezRATE/Header/Header.container';
import CandidateHeader from './Header/header.container';
import UserNotification from './UserNotification';
import { DepartmentContext } from 'DepartmentWrapper';
import RezRateBanner from 'Body/RezRATE/Common/RezRateBanner/RezRateBanner';

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: rgb(255, 255, 255);
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
`;

const Layout = (props) => {
  const { children, location, user, embedded, partDocumentTitle } = props;
  const [padding, setPadding] = useState('0');
  const [showUnSetSeasonApplicationDatesBanner, setShowUnSetSeasonApplicationDatesBanner] = useState(false);

  const dContext = useContext(DepartmentContext);
  const { season, department } = dContext;
  const { pathname } = location;

  const setTitle = (conditionalText) => {
    if (!conditionalText) {
      document.title = 'RezRATE';
      return;
    }

    document.title = `RezRATE ${conditionalText}`;
  };

  useEffect(() => {
    switch (true) {
      case pathname === '/user/evaluate':
        setTitle('Evaluator Portal');
        break;
      case pathname === '/':
        setTitle('Program Selection');
        break;
      case pathname.includes('/candidate/schedule/'):
        setTitle(`Schedule ${partDocumentTitle}`);
        break;
      case pathname.includes('/candidate/'):
        setTitle('Registration');
        break;
      default:
        setTitle();
    }
  }, [pathname, partDocumentTitle]);

  useEffect(() => {
    if (window.mobilecheck()) {
      setPadding('0px');
    } else {
      setPadding('15px');
    }
  }, []);

  useEffect(() => {
    if (season) {
      if (season.StartDate == null || season.EndDate == null) {
        setShowUnSetSeasonApplicationDatesBanner(true);
      } else {
        setShowUnSetSeasonApplicationDatesBanner(false);
      }
    }
  }, [season]);

  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  }

  // different headers for different views
  const isCandidatePage = location.pathname.startsWith('/candidate') || location.pathname.startsWith('/unsubscribe');

  const noHeader =
    location.pathname.startsWith('/files') ||
    location.pathname.startsWith('/viewFile') ||
    location.pathname.startsWith('/logo') ||
    location.pathname.startsWith('/coordinator/schedule');

  if (noHeader) {
    document.body.style.backgroundColor = '#fff';
    return children;
  }

  if (isCandidatePage) {
    // document.body.style.backgroundColor = 'rgb(238, 238, 238)';
  } else {
    document.body.style.backgroundColor = '#fff';
  }

  const conditionalHeader = () => {
    if (isCandidatePage) {
      return (
        <HeaderWrapper>
          <CandidateHeader />
        </HeaderWrapper>
      );
    } else {
      return (
        <div style={{ width: '100%', backgroundColor: '#333333' }}>
          <UserHeaderContainer />
          {showUnSetSeasonApplicationDatesBanner &&
          location.pathname.includes('administrator') &&
          department &&
          department.ApplicationSystem === 'direct_application' ? (
            <RezRateBanner
              message={
                <span>
                  Season's Application Start/End dates are not set! <b>Click to here to set them.</b>
                </span>
              }
              textColor={'#ffffff'}
              paddingRight={0}
              paddingLeft={0}
              backgroundColor="#ed723e"
              onClick={() => {
                props.history.push('/administrator/settings/directApplication');
              }}
            />
          ) : null}
        </div>
      );
    }
  };

  return (
    <div style={{ display: 'flex', height: ' 100%', width: '100%' }}>
      {props.userInfo && props.userInfo.user && props.userInfo.user.demo && <Demo />}

      <Container fluid={true} className="blorp">
        <div id="header_notification_wrapper">
          {!embedded && <Row>{conditionalHeader()}</Row>}
          <UserNotification />
        </div>

        <Row style={{ paddingLeft: padding, paddingRight: padding, paddingTop: padding }}>
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Layout);
