import {
  faEnvelope,
  faExclamationTriangle,
  faFilter,
  faInfoCircle,
  faLock,
  faSpinner,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as axios, default as Axios } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  PopoverBody,
  Row,
  Spinner,
  Popover,
} from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import EvaluatorRedirect from '../EvaluatorRedirect';
import logoPlaceHolder from '../../assets/images/header/rezRate.gif';
import { VelocityComponent } from 'velocity-react';
import { formatBearerToken, evaluatorTypeEnum } from '../../Common.functions';
import { DepartmentContext } from '../../DepartmentWrapper';
import { fetchDataAgnostic, fetchDataAuth0 } from '../../Services/dataApi';
import AdminSuperUserPanel from '../RezRATE/AdminLanding/AdminSuperUserPanel/AdminSuperUserPanel';
import Loading from 'Body/Statuses/Loading';
import { clone } from 'lodash';
import style from 'Body/CreateNewEntities/EntityWrapper.style';

// Revoke:ACCESSTOKENID - revokes a specific access token.
// RevokeAndForceNew - Revokes all access tokens and issues a new access token.
// ForceNew - Grants a new access token leaving any existing tokens unmodified.
// Refresh - Refreshes existing access token or issues a new one.

const overlayStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
  left: '0px',
  top: '0px',
  opacity: '.3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const filterOutDepartmentsWithoutPermissions = (data) => {
  return data.filter((item) => {
    if (!item.Permissions) return false;

    if (item.Permissions && item.Permissions.length === 0) return false;

    return true;
  });
};

const filterOutDepartmentPermissionsDuplicates = (data) => {
  const newArray = [];
  data.forEach((item) => {
    const filtered = item.Permissions.filter((thing, index) => {
      if (thing.selectionAction === 0) {
        return false;
      }

      return (
        index ===
        item.Permissions.findIndex((obj) => {
          return obj.selectionDisplay === thing.selectionDisplay;
        })
      );
    });
    item.Permissions = filtered;
  });

  return data;
};

const CancelToken = axios.CancelToken;

let cancel;

const isSpecificDate = (dateParameter, dateToCompare) => {
  const today = new Date(dateToCompare);
  return (
    dateParameter.getDate() === today.getDate() &&
    dateParameter.getMonth() === today.getMonth() &&
    dateParameter.getFullYear() === today.getFullYear()
  );
};

require('velocity-animate');
require('velocity-animate/velocity.ui');

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  paddingtop: 10px;
  height: 100%;
`;

const WhiteWrapper = styled.div`
  border-radius: 25px;
`;

const Login = (props) => {
  const formData = useRef(null);
  const loginInput = useRef(null);
  const passwordInput = useRef(null);
  const incorrectPasswordDiv = useRef(null);
  const [userDetails, setUserDetails] = useState();
  const [showError, setShowError] = useState(false);
  const [departmentChosenPhase, setDepartmentChosenPhase] = useState(1);
  const [chosenDepartment, setChosenDepartment] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [allowCancelRequest, setAllowCancelRequest] = useState(true);
  const [isCandidate, setIsCandidate] = useState(null);
  const [departmentChoices, setDepartmentChoices] = useState([]);
  const [departmentChoicesToDisplay, setDepartmentChoicesToDisplay] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [redirectToEvaluation, setRedirectToEvaluation] = useState(false);
  const [loginLevel, setLoginLevel] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [userForgotPassword, setUserForgetPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showSuperUserPanel, setShowSuperUserPanel] = useState(false);
  // const [axiosRequest, setAxiosRequest]
  const [username, setUsername] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [searchString, setSearchString] = useState();
  const [showOrgFilter, setShowOrgFilter] = useState(false);
  const [isFetchingDetailedDepartment, setIsFetchingDetailedDepartment] = useState(false);

  const [filterPk, setFilterPk] = useState();
  const query = new URLSearchParams(useLocation().search);
  const dContext = useContext(DepartmentContext);

  // const { department, season, setDepartment } = dContext;

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  const getErrorMessage = (passwordBlank, emailBLank, invalidEmail) => {
    if (error) {
      return 'Service temporarily unavailable';
    }
    if (passwordBlank && emailBLank) {
      return 'Fields must not be empty';
    }

    if (passwordBlank && invalidEmail) {
      return 'Must have valid email and password must not be empty';
    }

    if (invalidEmail) {
      return 'Must have valid email';
    }

    if (passwordBlank) {
      return 'Password must not be empty';
    }

    if (emailBLank) {
      return 'Email must not be empty';
    }
  };

  useEffect(() => {
    // if the user changes the username or passowrd cancel the login request
    if (allowCancelRequest && cancel) {
      cancel();
      setAllowCancelRequest(false);
      setDisableButton(false);
      setShowError(false);
    }

    // clear show error if user starts entering new information
    if (showError === true) setShowError(false);
  }, [props.email, password]);

  const incorrectPasswordAnimation = () => {
    incorrectPasswordDiv.current.velocity('callout.shake');
    // Velocity(incorrectPasswordDiv.current, [{ left: '5px' }], 500).then((e) => {});
  };

  useEffect(() => {
    if (departmentChosenPhase == 1) {
      getUserDetails();
    }
  }, [departmentChosenPhase]);

  // if this page gets remounted clear the user so they don't end up showing the wrong place
  useEffect(() => {
    props.setUser(null);
  }, []);

  useEffect(() => {
    // only redirect if season and department are set
    // if (showSuperUserPanel) {
    //   return;
    // }

    // TODO - Check why on switch, it redirects to role picker instead of dept picker.

    const { department, season } = dContext;

    if (department && season) {
      // setLoadingOverlay(true);
      const { EnableAdmin = false, EnableEvaluator = false, EnableSuperUser = false } = department;
      let isEvaluatePage = false;
      const roleChoice = sessionStorage.getItem('roleChoice');

      if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.path &&
        props.location.state.path.includes('/user/evaluate/')
      ) {
        isEvaluatePage = true;
      }
      if ((EnableSuperUser || EnableAdmin) && EnableEvaluator && roleChoice == null) {
        return setDepartmentChosenPhase(2);
      }

      if (EnableEvaluator && (roleChoice == null || roleChoice === 'null')) {
        // the evaluator page needs special attention so if it's any other page
        // just push them to the normal route
        if (!isEvaluatePage && props.location && props.location.state && props.location.state.path) {
          return props.history.push(props.location.state.path);
        }
        setRedirectToEvaluation(true);
        // getUserToken(chosenDepartment);
        setLoadingOverlay(false);
        return;
        // send them to new portal
      } else if (EnableAdmin || EnableSuperUser) {
        // if user was being redirected from another page direct them back to that page

        if (props.location.state && props.location.state.path) {
          props.history.push(props.location.state.path);
        } else {
          props.history.push(`/administrator`);
        }
      }
    }
  }, [JSON.stringify(dContext)]);

  useEffect(() => {
    getDepartments();
    const emailLogin = document.getElementById('emailLogin');
    if (emailLogin) {
      emailLogin.focus();
    }
  }, []);

  useEffect(() => {
    setSearchString('');
    filterBySearchString('');
    getOrganizationsFromDepartmentChoices(departmentChoices);
  }, [departmentChoices]);

  useEffect(() => {
    filterBySearchString(searchString);
  }, [searchString]);

  useEffect(() => {
    filterBySearchString(searchString);
  }, [selectedOrganization]);

  const selectRole = (type) => {
    const { setDepartment } = dContext;
    if (type === 'admin') {
      sessionStorage.setItem('roleChoice', 'admin');
      setDepartment((prevState) => {
        // eslint-disable-next-line
        let clone = structuredClone(prevState);
        clone.roleType = 'administrator';
        return clone;
      });
      // need to set this back in case they go back to the login page
      setDepartmentChosenPhase(1);
      props.history.push('/administrator');
    } else if (type === 'evaluator') {
      sessionStorage.setItem('roleChoice', 'evaluator');
      setDepartment((prevState) => {
        // eslint-disable-next-line
        let cloneDept = structuredClone(prevState);
        cloneDept.roleType = 'evaluator';
        return cloneDept;
      });

      // need to set this back in case they go back to the login page
      setDepartmentChosenPhase(1);

      setRedirectToEvaluation(true);
    }
  };

  const getDepartments = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAuth0('user/allDepartments', null, formatBearerToken(token))
          .then((result) => {
            if (result.data.length == 1) {
              selectDepartment(result.data[0]);
              return;
            }

            setDepartmentChoices(result.data);
            setIsLoading(false);
            const { history } = props;
            const { location } = history;
            const showSuperUserPanelFromURL = query.get('showSuperUserPanel');
            if (showSuperUserPanelFromURL === 'true') {
              setShowSuperUserPanel(true);
              history.push('/');
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log('getDepartments err: ', err);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log('getDepartments err: ', err);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to retrieve departments',
              type: 'error',
              icon: 'error',
            });
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getOrganizationsFromDepartmentChoices = (choices) => {
    const newOrgs = [];
    choices.forEach((c) => {
      const existingOrg = newOrgs.find((o) => {
        return (c.pkUUID_Org != null && c.pkUUID_Org == o.pkUUID_Org) || c.OrganizationName === o.OrganizationName;
      });

      if (!existingOrg) {
        newOrgs.push({
          OrganizationName: c.OrganizationName,
          OrganizationLogo: c.OrganizationLogo || c.DepartmentLogo,
          pkUUID_Org: c.pkUUID_Org,
        });
      }
    });

    newOrgs.sort((a, b) => {
      const stringA = a.OrganizationName ? a.OrganizationName.toLowerCase() : '';
      const stringB = b.OrganizationName ? b.OrganizationName.toLowerCase() : '';
      if (stringA < stringB) {
        return -1;
      }
      if (stringA > stringB) {
        return 1;
      }
      return 0;
    });
    setOrganizations(newOrgs);
  };

  const getUserDetails = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department/user/permissions', null, formatBearerToken(token))
          .then((res) => {
            setUserDetails(res.data);
          })
          .catch((err) => {
            console.log('getUserDetails err: ', err);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const selectDepartment = (department, cb) => {
    const { pk_Department } = department;
    const { setReduxDate, setReduxDateArray } = props;
    setReduxDate({});
    // setReduxDateArray(null);
    setIsFetchingDetailedDepartment(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department', { pk_Department }, formatBearerToken(token))
          .then((result) => {
            const detailedDepartment = clone(result.data.department);
            sessionStorage.setItem('department', pk_Department);

            dContext.setDepartment(detailedDepartment);
            setIsFetchingDetailedDepartment(false);
            if (cb) {
              cb();
            }
          })
          .catch((err) => {
            if (cb) {
              cb(true);
            }
            Swal.fire({
              title: 'No Program Details!',
              text: 'Failed to retrieve Program details. Please contact RezRATE support.',
              type: 'error',
              icon: 'error',
            });
            console.log('err: ', err);
            Swal.fire({
              title: 'Error!',
              text: 'Unable to access department. Please try again later.',
              type: 'error',
              icon: 'error',
            });

            setIsFetchingDetailedDepartment(false);
            // sessionStorage.removeItem('department');
          });
      })
      .catch((err) => {
        if (cb) {
          cb(true);
        }
        console.log('err selectDepartment getTokenSilently: ', err);
        setIsFetchingDetailedDepartment(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const filterBySearchString = (str, setState = true) => {
    const newDepartmentChoicesToDisplay = departmentChoices.filter((dc) => {
      //       domain ( the portion of the institutional email that comes after @ sign. for schwirian@uthscsa.edu, it would be uthscsa.edu)
      // -POC Name (First, Last or Last First -- we need an array of POCs for each department to be made available)
      // -Department Name
      // -Organization Name
      // -Department Short Name
      // -Organization Short Name

      if (
        selectedOrganization &&
        !(
          (dc.pkUUID_Org != null && dc.pkUUID_Org == selectedOrganization.pkUUID_Org) ||
          (dc.pkUUID_Org == null && dc.OrganizationName === selectedOrganization.OrganizationName)
        )
      ) {
        return false;
      }
      if (!searchString) {
        return true;
      }

      if (dc.DepartmentName && dc.DepartmentName.toLowerCase().indexOf(str.toLowerCase()) >= 0) {
        return true;
      }

      if (dc.OrganizationName && dc.OrganizationName.toLowerCase().indexOf(str.toLowerCase()) >= 0) {
        return true;
      }

      // TODO: Organization and dept ShortName, POC array checking for names.
    });

    if (!setState) {
      return departmentChoices;
    }
    setDepartmentChoicesToDisplay(
      newDepartmentChoicesToDisplay.filter((item) => item.OrgEnableHidden === false && item.DeptEnableHidden === false),
    );
  };

  const renderOrgFilterPopover = () => {
    return (
      <Popover target="orgFilter" trigger="legacy" flip={false} placement="bottom" isOpen={showOrgFilter}>
        <PopoverBody>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column', height: 300, overflowY: 'scroll' }}>
                <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}></div>
                {organizations.map((o, i) => {
                  let backgroundColor = i % 2 == 0 ? 'white' : '#e8e8e8';

                  if (JSON.stringify(selectedOrganization) === JSON.stringify(o)) {
                    backgroundColor = '#d9f5ff';
                  }
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '10px 5px',
                        backgroundColor,
                        cursor: 'pointer',
                      }}
                      value={JSON.stringify(o)}
                      onClick={() => {
                        // const { pkUUID_Org, OrganizationName } = o;
                        // filterByOrganization(pkUUID_Org || OrganizationName);
                        setSelectedOrganization(o);
                        setShowOrgFilter(false);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 20,
                          marginRight: 10,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={o.OrganizationLogo || logoPlaceHolder} width={20} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>{o.OrganizationName}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <Button
                size="sm"
                color="danger"
                onClick={() => {
                  setSelectedOrganization(null);
                  setShowOrgFilter(false);
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderDepartmentsSection = () => {
    return (
      <div
        style={{
          paddingTop: '15px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          <h5>Please select a program. . .</h5>
        </div>

        {userDetails && userDetails.EnableSuperUser ? (
          <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
            <Button
              onClick={() => {
                setShowSuperUserPanel(true);
              }}
            >
              {' '}
              <FontAwesomeIcon icon={faTable} style={{ marginRight: 10 }} />
              Super User Panel
            </Button>
          </div>
        ) : (
          ''
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            marginBottom: 10,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '40%', maxWidth: 290, paddingRight: 10 }}>
            <Label for="search">Search</Label>
            <Input
              type="text"
              id="search"
              name="search"
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
          </div>

          {userDetails && userDetails.EnableSuperUser ? (
            <div style={{ display: 'flex', flexDirection: 'column', width: '40%', maxWidth: 290, paddingTop: 28 }}>
              <Button
                id="orgFilter"
                color={selectedOrganization ? 'primary' : 'secondary'}
                onClick={() => {
                  setShowOrgFilter(!showOrgFilter);
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 20,
                      marginRight: 10,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedOrganization ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 20,
                            marginRight: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={selectedOrganization.OrganizationLogo || logoPlaceHolder} width={20} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {selectedOrganization ? selectedOrganization.OrganizationName : '(Unnamed Org)'}
                        </div>
                      </div>
                    ) : (
                      'Showing All Orgs'
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: 20, marginRight: 10 }}></div>
                </div>
                {renderOrgFilterPopover()}
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        {renderDepartmentList()}
      </div>
    );
  };

  const renderDepartmentList = () => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 800,
            height: '50vh',
            overflowY: 'scroll',
          }}
        >
          {/* filter out all departments that are hidden either on the dept level or organization level */}
          {departmentChoicesToDisplay && departmentChoicesToDisplay.length > 0 ? (
            departmentChoicesToDisplay.map((department, i) => {
              const alternatingColor = i % 2 ? '#f2f2f2' : '#fff';
              const { DepartmentName, OrganizationName, DepartmentLogo, pk_Department } = department;
              return (
                <div
                  className={isFetchingDetailedDepartment ? '' : 'hover_blue'}
                  key={`department_${pk_Department}_${DepartmentName}_${OrganizationName}`}
                  style={{
                    padding: '20px',
                    cursor: isFetchingDetailedDepartment ? 'not-allowed' : 'pointer',
                    backgroundColor: isFetchingDetailedDepartment ? 'lightgray' : alternatingColor,
                  }}
                  disabled={isFetchingDetailedDepartment ? true : false}
                  onClick={() => {
                    selectDepartment(department);

                    // not currently using default departments
                    // setAsUsersDefaultDepartment(department.pk_Department);
                    // props.history.back();
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        maxHeight: '70px',
                        maxWidth: '70px',
                        paddingRight: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img src={DepartmentLogo || logoPlaceHolder} alt="logo" style={{ width: '100%' }} />
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          paddingBottom: 2,
                          marginBottom: 2,
                          borderBottom: '1px solid grey',
                        }}
                      >
                        {DepartmentName}
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                        }}
                      >
                        {OrganizationName}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 80,
              }}
            >
              No Departments Found
            </div>
          )}
        </div>
      </div>
    );
  };

  const determineContents = () => {
    switch (departmentChosenPhase) {
      case 0:
        if (isCandidate == null) {
          return (
            <div style={{ marginTop: '10vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  {' '}
                  Select if Candidate or Not my doods
                </div> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                  <Button
                    color="primary"
                    // style={{ width: 100 }}
                    onClick={() => {
                      setIsCandidate(true);
                    }}
                  >
                    {' '}
                    I am a Candidate
                  </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <Button
                    color="primary"
                    // style={{ width: 100 }}
                    onClick={() => {
                      setIsCandidate(false);
                      setDepartmentChosenPhase(1);
                    }}
                  >
                    I am an Evaluator or Administrator
                  </Button>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              style={{
                marginTop: '10vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid gray',
                  backgroundColor: '#ccd7e8',
                  padding: 20,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                  {/* <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', }}></div> */}
                  <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 30, marginRight: 10 }} />
                  <b style={{ fontSize: 15 }}>Candidates do not need to log into RezRATE. </b>
                </div>

                <ul>
                  <li>Please check your email for invitations and interview day schedule links.</li>
                  <li>These messages are unique to you. Do not share them with others.</li>
                  <li>If you’re unable to locate your email, please contact the program you’ve applied to directly.</li>
                </ul>
              </div>
            </div>
          );
        }

        break;
      case 1:
        if (isLoading) {
          return (
            <div style={{ marginTop: '10vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ height: 100 }}>
                <Loading options={{ labelText: 'Fetching Departments. . .' }} />
              </div>
            </div>
          );
        } else if (showSuperUserPanel) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <AdminSuperUserPanel
                  departments={departmentChoices}
                  setShowSuperUserPanel={setShowSuperUserPanel}
                  selectDepartment={selectDepartment}
                  selectRole={selectRole}
                  getDepartments={getDepartments}
                />
              </div>
            </div>
          );
        } else if (isAuthenticated && isLoading == false && departmentChoices.length === 0) {
          return (
            <Card style={{ marginTop: '10vh' }}>
              <CardHeader></CardHeader>
              <CardBody>
                <div
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', aligntItems: 'center' }}
                >
                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: 60, color: 'red' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '5vh',
                      fontWeight: 'bold',
                    }}
                  >
                    You are currently not associated with a program in RezRATE - please contact your coordinator.
                  </div>
                </div>
              </CardBody>
            </Card>
          );
        } else if (departmentChoices && departmentChoices.length > 0) {
          return renderDepartmentsSection();
        } else {
          return <></>;
        }
        break;
      case 2:
        break;
    }
    if (departmentChosenPhase === 1) {
      if (showSuperUserPanel) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <AdminSuperUserPanel
                departments={departmentChoices}
                setShowSuperUserPanel={setShowSuperUserPanel}
                selectDepartment={selectDepartment}
                selectRole={selectRole}
              />
            </div>
          </div>
        );
      } else if (departmentChoices && departmentChoices.length > 0) {
        return (
          <div
            style={{
              paddingTop: '15px',
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              <h5>Please select a program. . .</h5>
            </div>

            {userDetails && userDetails.EnableSuperUser ? (
              <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                <Button
                  onClick={() => {
                    setShowSuperUserPanel(true);
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faTable} style={{ marginRight: 10 }} />
                  Super User Panel
                </Button>
              </div>
            ) : (
              ''
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                marginBottom: 10,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '40%', maxWidth: 290, paddingRight: 10 }}>
                <Label for="search">Search</Label>
                <Input
                  type="text"
                  id="search"
                  name="search"
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </div>

              {userDetails && userDetails.EnableSuperUser ? (
                <div style={{ display: 'flex', flexDirection: 'column', width: '40%', maxWidth: 290, paddingTop: 28 }}>
                  <Button
                    id="filter"
                    color={selectedOrganization ? 'primary' : 'secondary'}
                    onClick={() => {
                      setShowOrgFilter(!showOrgFilter);
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 20,
                          marginRight: 10,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faFilter} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {selectedOrganization ? (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 20,
                                marginRight: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img src={selectedOrganization.OrganizationLogo || logoPlaceHolder} width={20} />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {selectedOrganization ? selectedOrganization.OrganizationName : '(Unnamed Org)'}
                            </div>
                          </div>
                        ) : (
                          'Showing All Orgs'
                        )}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: 20, marginRight: 10 }}></div>
                    </div>
                  </Button>
                  <Popover target="filter" trigger="legacy" flip={false} placement="bottom" isOpen={showOrgFilter}>
                    <PopoverBody>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', height: 300, overflowY: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}></div>
                            {organizations.map((o, i) => {
                              let backgroundColor = i % 2 == 0 ? 'white' : '#e8e8e8';

                              if (JSON.stringify(selectedOrganization) === JSON.stringify(o)) {
                                backgroundColor = '#d9f5ff';
                              }
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '10px 5px',
                                    backgroundColor,
                                    cursor: 'pointer',
                                  }}
                                  value={JSON.stringify(o)}
                                  onClick={() => {
                                    // const { pkUUID_Org, OrganizationName } = o;
                                    // filterByOrganization(pkUUID_Org || OrganizationName);
                                    setSelectedOrganization(o);
                                    setShowOrgFilter(false);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: 20,
                                      marginRight: 10,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img src={o.OrganizationLogo || logoPlaceHolder} width={20} />
                                  </div>
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{o.OrganizationName}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                          }}
                        >
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                              setSelectedOrganization(null);
                              setShowOrgFilter(false);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
              ) : (
                ''
              )}
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: 800,
                  height: '50vh',
                  overflowY: 'scroll',
                }}
              >
                {/* filter out all departments that are hidden either on the dept level or organization level */}
                {departmentChoicesToDisplay && departmentChoicesToDisplay.length > 0 ? (
                  departmentChoicesToDisplay.map((department, i) => {
                    const alternatingColor = i % 2 ? '#f2f2f2' : '#fff';
                    const { DepartmentName, OrganizationName, DepartmentLogo, pk_Department } = department;
                    return (
                      <div
                        className={isFetchingDetailedDepartment ? '' : 'hover_blue'}
                        key={`department_${pk_Department}_${DepartmentName}_${OrganizationName}`}
                        style={{
                          padding: '20px',
                          cursor: isFetchingDetailedDepartment ? 'not-allowed' : 'pointer',
                          backgroundColor: isFetchingDetailedDepartment ? 'lightgray' : alternatingColor,
                        }}
                        disabled={isFetchingDetailedDepartment ? true : false}
                        onClick={() => {
                          selectDepartment(department);

                          // not currently using default departments
                          // setAsUsersDefaultDepartment(department.pk_Department);
                          // props.history.back();
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              maxHeight: '70px',
                              maxWidth: '70px',
                              paddingRight: '15px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img src={DepartmentLogo || logoPlaceHolder} alt="logo" style={{ width: '100%' }} />
                          </div>
                          <div>
                            <div
                              style={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                paddingBottom: 2,
                                marginBottom: 2,
                                borderBottom: '1px solid grey',
                              }}
                            >
                              {DepartmentName}
                            </div>
                            <div
                              style={{
                                fontSize: 15,
                              }}
                            >
                              {OrganizationName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 80,
                    }}
                  >
                    No Departments Found
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <div style={{ padding: '15px' }}>
          <div style={{ textAlign: 'center' }}>
            <h5>Which role would you like to perform under?</h5>
          </div>
          <div>
            <div
              className="hover_blue"
              style={{ padding: '20px', cursor: 'pointer' }}
              onClick={() => {
                selectRole('evaluator');
              }}
            >
              Evaluator
            </div>
            <div
              className="hover_blue"
              style={{ padding: '20px', cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)' }}
              onClick={() => {
                selectRole('admin');
              }}
            >
              Administrator
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Wrapper>
      {/* There's a decent amount of things that need to be done for this redirect,
              so invoke it as it's own component in order to avoid code repitiion */}
      {redirectToEvaluation && (
        <EvaluatorRedirect
          reduxLocation={props.location}
          type={evaluatorTypeEnum.evaluator}
          callback={() => {
            setRedirectToEvaluation(false);
          }}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {' '}
        {user && (
          <div>
            Hello, <b>{user.name}</b>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{determineContents()}</div>
      {/* {loadContent()} */}
    </Wrapper>
  );
};

export default Login;
