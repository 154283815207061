import { useAuth0 } from 'auth0/reactAuth0Spa';
import { formatBearerToken } from 'Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';
import React, { useState, useEffect, useContext } from 'react';
import { fetchDataAgnostic, putData } from 'Services/dataApi';
import style from './DirectApplicationSettings.style';
import { Button, Card, CardBody, CardHeader, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from 'react-widgets/cjs';
import { useAlert } from 'react-alert';
import RezRateBanner from 'Body/RezRATE/Common/RezRateBanner/RezRateBanner';
import moment from 'moment';

const DirectApplicationSettings = () => {
  const [settings, setSettings] = useState({});
  const [isGettingSettings, setIsGettingSettings] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGettingApplicationSettings, setIsGettingApplicationSettings] = useState(false);
  const [departmentApplicantData, setDepartmentApplicantData] = useState([]);
  const [baseURL, setBaseURL] = useState();
  const [editableStartDate, setEditableStartDate] = useState(null);
  const [editableEndDate, setEditableEndDate] = useState(null);

  const [activeSeason, setActiveSeason] = useState(null);

  const dContext = useContext(DepartmentContext);
  const { department, season } = dContext;
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const alert = useAlert();

  useEffect(() => {
    // Fetch or initialize settings here
    // Example: setSettings({ key: 'value' });

    const { protocol, hostname, port } = window.location;
    const newBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    setBaseURL(newBaseUrl);
    getSettings();
    getApplicantsData();
  }, []);

  useEffect(() => {
    if (season) {
      getSettings();
    }
  }, [season]);

  const saveSeason = () => {
    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: department.pk_Department, pk_Season: season.pk_Season },
          {
            seasonObject: {
              ApplicationStartDate: moment(editableStartDate).format(),
              ApplicationEndDate: moment(editableEndDate).format(),
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Season dates saved successfully');
            getSettings();
          })
          .catch((err) => {
            console.log('err: ', err);
            alert.error('Error saving season dates');
          })
          .finally(() => {
            setIsSaving(false);
          });
      })
      .catch((err) => {
        setIsSaving(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getSettings = () => {
    setIsGettingSettings(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department', { pk_Department: department.pk_Department }, formatBearerToken(token))
          .then((res) => {
            const { data = {} } = res;
            const { season = [] } = data;
            setIsGettingSettings(false);
            updateActiveason(season);
          })
          .catch((err) => {
            setIsGettingSettings(false);
          });
      })
      .catch((err) => {
        console.log('err: ', err);
        setIsGettingSettings(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getApplicantsData = () => {
    setIsGettingApplicationSettings(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/application/list',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setDepartmentApplicantData(res.data.Department);
          })
          .catch((err) => {
            console.log('fetchDepartmentApplicantData err: ', err);
          })
          .finally(() => {
            setIsGettingApplicationSettings(false);
          });
      })
      .catch((err) => {
        setIsGettingApplicationSettings(false);

        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateActiveason = (newSeasonList) => {
    const newActiveSeason = newSeasonList.find((s) => s.pk_Season === dContext.season.pk_Season);
    setActiveSeason(newActiveSeason);

    setEditableStartDate(newActiveSeason.ApplicationStartDate ? new Date(newActiveSeason.ApplicationStartDate) : null);
    setEditableEndDate(newActiveSeason.ApplicationEndDate ? new Date(newActiveSeason.ApplicationEndDate) : null);
  };

  const hasDateChange = () => {
    const formattedEditableEndDate = editableEndDate ? moment(editableEndDate).format() : null;
    const formattedEditableStartDate = editableStartDate ? moment(editableStartDate).format() : null;

    const formattedStartDate = activeSeason ? moment(activeSeason.ApplicationStartDate).format() : null;
    const formattedEndDate = activeSeason ? moment(activeSeason.ApplicationEndDate).format() : null;

    if (activeSeason) {
      return (
        editableStartDate &&
        editableEndDate &&
        (formattedEditableEndDate !== formattedEndDate || formattedEditableStartDate !== formattedStartDate)
      );
    }
  };

  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <Card>
          <CardHeader>
            <div style={{ ...style.simpleRow, alignItems: 'center', fontWeight: 'bold' }}>
              Application Window for {activeSeason ? activeSeason.SeasonName : ''}
            </div>
          </CardHeader>
          <CardBody>
            <div
              style={{
                ...style.simpleRow,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                <RezRateBanner
                  message={activeSeason && !activeSeason.Active ? 'This is an inactive season!' : null}
                  backgroundColor={activeSeason && !activeSeason.Active ? '#f0ad4e' : null}
                />
              </div>
            </div>

            <div
              style={{
                ...style.simpleRow,
                paddingRight: '10%',
                paddingLeft: '10%',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 30, paddingRight: 30 }}>
                <div style={style.simpleRow}>Start Accepting Applications on</div>
                <div style={{ ...style.simpleColumn, width: '100%' }}>
                  <DatePicker
                    placeholder="Select a start date"
                    value={editableStartDate}
                    disabled={isGettingSettings || isSaving}
                    onChange={(e) => {
                      setEditableStartDate(e);
                    }}
                    valueFormat={{ month: 'short', year: 'numeric' }}
                    valueEditFormat={{ dateStyle: 'long' }}
                    valueDisplayFormat={{ dateStyle: 'long' }}
                  />
                </div>
              </div>
              <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 30, paddingRight: 30 }}>
                <div style={style.simpleRow}>End Accepting Applications on</div>
                <div style={style.simpleRow}>
                  <div style={{ ...style.simpleColumn, width: '100%' }}>
                    <DatePicker
                      placeholder="Select an end date"
                      value={editableEndDate}
                      disabled={isGettingSettings || isSaving}
                      onChange={(e) => {
                        setEditableEndDate(e);
                      }}
                      valueFormat={{ month: 'short', year: 'numeric' }}
                      valueEditFormat={{ dateStyle: 'long' }}
                      valueDisplayFormat={{ dateStyle: 'long' }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
              <Button
                size="sm"
                disabled={!hasDateChange() || isSaving}
                style={{ width: 150 }}
                color={'success'}
                onClick={saveSeason}
              >
                Save
              </Button>
            </div>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader>
            {' '}
            <b>Direct Application Link</b>
          </CardHeader>
          <CardBody>
            <div
              style={{
                ...style.simpleRow,
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: 30,
                paddingLeft: 30,
                marginBottom: 10,
              }}
            >
              <div style={{ ...style.simpleColumn, width: '90%' }}>
                {' '}
                <InputGroup>
                  <Input
                    type="text"
                    size="sm"
                    disabled={true}
                    value={`${baseURL}/applicant/profile/new_application?applicationCode=${departmentApplicantData.ApplicantCode}`}
                    style={{ width: 150 }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="success"
                      size="sm"
                      disabled={isGettingApplicationSettings}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${baseURL}/applicant/profile/new_application?applicationCode=${departmentApplicantData.ApplicantCode}`,
                        );
                        alert.success('URL copied to clipboard');
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Copy
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default DirectApplicationSettings;
