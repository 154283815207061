const style = {
  simpleColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputContainers: {
    marginTop: 10,
  },
  spaceBetweenRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
};

export default style;
